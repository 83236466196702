import {
  Checkbox,
  Grid,
  Paper,
  FormControl,
  FormHelperText,
  FormControlLabel,
} from '@material-ui/core';
/// //////////React Boostrap/////////
// import { Button, Form } from 'react-bootstrap';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from '../../../../css/baseStyle';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '1% 8% 4% 8%',

    '& > *': {
      //   margin: theme.spacing(1),
      //   width: theme.spacing(16),
      //   height: theme.spacing(16),

      width: '100%',
      height: 'auto',
    },
  },
  depotTitle: {
    margin: '0% 0% 2% 2%',
    color: '#333232',
  },
  depotPaper: {
    // width: '100%',
    // height: 'auto',
    //margin: '4% 8% 4% 8%',

    padding: '3%',
    marginBottom: '4%',
  },
  depotSubTitle: {
    margin: '0% 0% 2% 0%',
    color: '#333232',
    float: 'left',
  },
  table: {
    minWidth: 700,
  },
  depotButtonTypo: {
    float: 'right',
  },
  depotButton: {
    color: '#fff',
    background: '#D52B1E',
    textAlign: 'center',
    '&:hover': {
      background: '#D52B1E',
    },
  },
  headerBottomPadding: {
    marginBottom: '20px',
  },
  labelCheckBoxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  labelText: {
    textAlign: 'left !important',
    font: 'normal normal normal 16px/21px Roboto',
    color: '#333232',
    opacity: '1',
  },
  textFieldMaxWidth: {
    width: '100% !important',
  },
  paperMaxWidth: {
    width: '100% !important',
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
}));

const consents = (props) => {
  const classes = useStyles();

  return (
    <>
      <Paper className={`${classes.paperMaxWidth} ${styles.marginBottom}`}>
        <Grid container>
          {props.states.Visible.get && (
            <FormControl error={props.marketingError} component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    id="marketingCheckbox"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    checked={props.states.Marketing.get}
                    disabled={props.isDisabled ?? false}
                    onChange={(event) =>
                      props.states.Marketing.set(!props.states.Marketing.get)
                    }
                  />
                }
                label={
                  <div>
                    I give my consent for the use of my personal information,
                    including the above email address, to provide me with
                    information about products (including promotional materials)
                    and/or services and access to new resources, in accordance
                    with the conditions provided in the
                    <a href="https://www.lillyprivacy.com/au-en/hcp">
                      {` Privacy Policy.`}
                    </a>{' '}
                    I may stop participating in this program at any time by
                    using the unsubscribe link provided in the communication or
                    as outlined in the
                    <a href="https://www.lillyprivacy.com/au-en/hcp">
                      {` Privacy Policy.`}
                    </a>
                  </div>
                }
                classes={{
                  label: styles.confirmationLabelFontSize,
                  root: styles.checkboxPadding,
                }}
              />
              {props.marketingError ? (
                <FormHelperText className={styles.checkboxhelperPadding}>
                  *Confirmation is required!!
                </FormHelperText>
              ) : (
                ''
              )}
            </FormControl>
          )}
        </Grid>
      </Paper>
      <Paper className={`${classes.paperMaxWidth} ${styles.marginBottom}`}>
        <Grid container>
          <FormControl error={props.privacyError} component="fieldset">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  id="confirmationCheckbox"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  checked={props.states.Privacy.get}
                  disabled={props.isDisabled ?? false}
                  onChange={(event) =>
                    props.states.Privacy.set(!props.states.Privacy.get)
                  }
                />
              }
              label={
                <div>
                  I confirm that I have been given the opportunity to read the{' '}
                  <a href="https://www.lilly.com.au/terms-of-use">
                    {` Terms of Use `}
                  </a>{' '}
                  and
                  <a href="https://www.lillyprivacy.com/au-en/hcp">
                    {` Privacy Policy `}
                  </a>
                  that describes how my personal information will be used by
                  Lilly, the terms of how Lilly may contact me, and information
                  about my rights (including the right to revoke consent, right
                  of correction and deletion, right for information about which
                  of my personal data are processed, and the right to access
                  these personal data). By agreeing (signing or checking this
                  box), I confirm that I have read, understood, and approved
                  that my personal data is treated as described in the
                  <a href="https://www.lillyprivacy.com/au-en/hcp">
                    {` Privacy Policy.`}
                  </a>
                </div>
              }
              classes={{
                label: styles.confirmationLabelFontSize,
                root: styles.checkboxPadding,
              }}
            />
            {props.privacyError ? (
              <FormHelperText className={styles.checkboxhelperPadding}>
                *Confirmation is required!!
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
          {/* <Grid item xs={1} className={classes.labelTextContainer}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            checked={props.states.Marketing.get}
            onChange={(event) =>
              props.states.Marketing.set(!props.states.Marketing.get)
            }
          />
        </Grid>
        <Grid container item xs={11} className={classes.labelCheckBoxContainer}>
          <Grid item xs={12} className={classes.labelCheckBoxContainer}>
          </Grid>
          <Grid item xs={12} className={classes.labelCheckBoxContainer}>
            <label className={classes.labelText} htmlFor="HCPName">
              I give my consent for the use of my personal information,
              including the above email address to provide me the information
              about which products (including promotional materials) and/or
              services and access to new resources, in accordance with the
              conditions provided in the Privacy Policy. I may stop
              participating in the program at any time by using the unsubscribe
              link provided in the communication as outlined in the{' '}
              <a href="https://www.lillyprivacy.com/au-en/hcp">
                {' '}
                Privacy Policy.
              </a>
            </label>
          </Grid>
        </Grid> */}
          {/* <Grid item xs={1} className={classes.labelTextContainer}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            checked={props.states.Privacy.get}
            onChange={(event) => props.states.Privacy.set(event.target.value)}
          />
        </Grid>
        <Grid container item xs={11} className={classes.labelCheckBoxContainer}>
          <Grid item xs={12} className={classes.labelCheckBoxContainer}>
            <h5>Terms of Use & Privacy Statement</h5>
          </Grid>
          <Grid item xs={12} className={classes.labelCheckBoxContainer}>
            <label className={classes.labelText} htmlFor="HCPName">
              I confirm that I have been given the opportunity to read the{' '}
              <a href="https://www.lilly.com.au/en/terms-of-use.aspx">
                Terms of Use
              </a>{' '}
              and{' '}
              <a href="https://www.lillyprivacy.com/au-en/hcp">
                Privacy Policy
              </a>{' '}
              that describes how my personal information will be used by Lilly,
              the terms of how Lilly may contact me and information about my
              rights (including the right to revoke consent, right of correction
              and deletion, right for information about which my personal data
              are processed, and the right to access these personal data). By
              agreeing (signing or checking this box), I confirm that I have
              read, understood and approved that my personal data is treated as
              described in the{' '}
              <a href="https://www.lillyprivacy.com/au-en/hcp">
                Privacy Policy
              </a>
              .
            </label>
          </Grid>
              </Grid>*/}
        </Grid>
      </Paper>
    </>
  );
};

export default consents;
