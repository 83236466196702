import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AddCircleOutline } from '@material-ui/icons';

import {
  Card,
  CardContent,
  Paper,
  Checkbox,
  Select,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  baseModal: {
    position: 'absolute',
    top: '20%',
    left: '10%',
    width: '80%',
    height: '60%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #D52B1E',
    borderRadius: '30px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  labelTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelCheckBoxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  labelText: {
    textAlign: 'left !important',
    font: 'normal normal normal 16px/21px Roboto',
    color: '#333232',
    opacity: '1',
  },
  textFieldMaxWidth: {
    width: '100% !important',
  },
  paperMaxWidth: {
    width: '100% !important',
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
  containerMargin: {
    marginBottom: '5px',
  },
  containerRightPadding: {
    paddingRight: '15px',
  },
}));

const HCPAddAddress = React.forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <Card className={classes.baseModal}>
      <CardContent>
        <h3 id="modal-title">Add new address:</h3>
        {/* <p id="modal-description">donde esta la biblioteca</p> */}
        <Grid container className={classes.containerMargin}>
          <Grid container item spacing={1} xs={6}>
            <Grid item xs={6} className={classes.labelTextContainer}>
              <label className={classes.labelText} htmlFor="HCPName">
                Address Line 1
              </label>
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={classes.textFieldMaxWidth}
                id="AddressLine1"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} xs={6}>
            <Grid item xs={6} className={classes.labelTextContainer}>
              <label className={classes.labelText} htmlFor="HCPName">
                Address Line 2
              </label>
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={classes.textFieldMaxWidth}
                id="AddressLine2"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.containerMargin}>
          <Grid container item spacing={1} xs={6}>
            <Grid item xs={6} className={classes.labelTextContainer}>
              <label className={classes.labelText} htmlFor="HCPName">
                Address Line 3
              </label>
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={classes.textFieldMaxWidth}
                id="AddressLine3"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} xs={6}>
            <Grid item xs={6} className={classes.labelTextContainer}>
              <label className={classes.labelText} htmlFor="AreaCode">
                Area Code
              </label>
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={classes.textFieldMaxWidth}
                id="AreaCode"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.containerMargin}>
          <Grid
            container
            item
            spacing={1}
            xs={12}
            className={classes.containerMargin}
          >
            <Paper className={classes.paperMaxWidth}>
              <Grid item xs={3} className={classes.labelTextContainer}>
                <Checkbox
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Grid>
              <Grid item xs={9} className={classes.labelCheckBoxContainer}>
                <label className={classes.labelText} htmlFor="HCPName">
                  I give my consent for the use of my personal information,
                  including the above email address, to provide me with
                  information about products (including promotional materials)
                  and/or services and access to new resources, in accordance
                  with the conditions provided in the Privacy Policy. I may stop
                  participating in this program at any time by using the
                  unsubscribe link provided in the communication or as outlined
                  in the Privacy Policy.
                </label>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row-reverse"
          justify="flex-start"
          className={classes.containerRightPadding}
        >
          <Button size="large" variant="contained">
            Submit
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
});

export default HCPAddAddress;
