import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// import './worker';
import createstore from '../../Store/store';
import AddNewAddress from './HCP/pages/addNewAddress';

//import Template from './components/partial/template';
// import our react pages
//import Demo from './components/pages/demo';
//import Home from './components';
import HCPForm from './HCP/pages/index';
import Dashboard from './pages/dashboard';
import HCPHome from './HCP/pages/HCPHome';
// function NewROle(props){
//   return <Role/>;
// }

const onUpdate = () => {
    return window.scrollTo(0, 0);
};

/*
    Usage: Nest your react routes under the switch route

    <Switch>
      <Route path="/cool-route" component={CoolRoute} />
    </Switch>

    In this example, this would render the Home page inside the Template accessible by visiting
    the root route.
*/
const formRouter = (props) => {
    const _store = createstore;
    console.log('--------------------------------------redux store');
    console.log(_store);
    return (
        //<Template>
        <Switch>
            {/* <Route exact path="/" component={Home} />
        <Route exact path="/demo" component={Demo} /> */}
            {/* // <Provider store={_store}> */}
            <Route exact path={`${props.path}/HCP`} component={HCPForm} />

            <Route exact path={`${props.path}/Home`} component={HCPHome} />
            <Route exact path={`${props.path}/addAddress`} component={AddNewAddress} />
            <Route path="*">
                <Redirect to={`${props.path}/Home`} />
            </Route>
            {/* <Route exact path={`${props.path}`} component={HCPHome} /> */}
            {/* /  </Provider> */}
            {/* <Route path="/Form/HCP" component={HCPForm} /> */}
        </Switch>
        //</Template>
    );
};
export default formRouter;
