/* eslint-disable no-multi-spaces */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-return */
import React from 'react';
/// //////////React Boostrap/////////
// import { Button, Form } from 'react-bootstrap';

// Material UI
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AddCircleOutlineIcon } from '@material-ui/icons';
import { Button, Typography, Paper, FormHelperText, Grid, Checkbox, Snackbar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';

import slice, { GetSessionInformation } from '../../../../Store/Slices/SessionSlice';
import MuiAlert from '@material-ui/lab/Alert';
// Customs
import HCPDetails from '../../components/HCPDetails';
import ProductDetails from '../../components/ProductDetails';
import OnClosureModal from '../../../common/components/onClosureModal';
import Consents from '../components/consents';
import styles from '../../../../css/baseStyle';
import axios from 'axios';
import Skeleton from '../../../common/components/Skeleton';
/// /States////

/// //Main////

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '1% 8% 4% 8%',

        '& > *': {
            //   margin: theme.spacing(1),
            //   width: theme.spacing(16),
            //   height: theme.spacing(16),

            width: '100%',
            height: 'auto',
        },
    },
    depotTitle: {
        margin: '0% 0% 2% 2%',
        color: '#333232',
    },
    depotPaper: {
        // width: '100%',
        // height: 'auto',
        //margin: '4% 8% 4% 8%',

        padding: '3%',
        marginBottom: '4%',
    },
    depotSubTitle: {
        margin: '0% 0% 2% 0%',
        color: '#333232',
        float: 'left',
    },
    table: {
        minWidth: 700,
    },
    depotButtonTypo: {
        float: 'right',
    },
    depotButton: {
        color: '#fff',
        background: '#D52B1E',
        textAlign: 'center',
        '&:hover': {
            background: '#D52B1E',
        },
    },
    headerBottomPadding: {
        marginBottom: '20px',
    },
    labelCheckBoxContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    labelText: {
        textAlign: 'left !important',
        font: 'normal normal normal 16px/21px Roboto',
        color: '#333232',
        opacity: '1',
    },
    textFieldMaxWidth: {
        width: '100% !important',
    },
    paperMaxWidth: {
        width: '100% !important',
        display: 'flex',
        flexGrow: 1,
        flexWrap: 'wrap',
    },
}));
class Address {
    constructor() {
        this.Line1 = '';
        this.Line2 = '';
        this.Line3 = '';
        this.AreaCode = '';
    }
}
class Brand {
    constructor() {
        this.Name = '';
        this.ID = '';
        this.Products = [];
        this.UniqueCode = '';
    }
}
class SubmitModel {
    constructor() {
        this.affiliate_id = 0;
        this.order_data = {};

        this.hcp_address = '';
        this.hcp_id = '';
        this.hcp_name = '';
        this.product_array = {};
        this.hcp_address_object = {};
        this.email = '';
        this.Specialty = '';
        this.atlantisCheckBox = '';
        this.attachments = {};
        this.created_on = '';
        this.created_by_user_id = 0;
        this.updated_on = '';
        this.updated_by_user_id = 0;
    }
}
const mainForm = (props) => {
    const [hcpName, setHcpName] = React.useState('');
    const [hcpID, setHcpID] = React.useState('');
    const [hcpAddress, setHcpAddress] = React.useState('');
    const [orderDetails, setOrderDetails] = React.useState([]);
    const [consentCheckBox, setconsentCheckBox] = React.useState(false);
    const [attachments, setAttachments] = React.useState([]);
    const [showClosure, setShowClosure] = React.useState(false);
    const [closureMessage, setClosureMessage] = React.useState();
    const [enableCancelRedirect, setEnableCancelRedirect] = React.useState(false);
    const [enableRedirect, setEnableRedirect] = React.useState(true);
    const [brandState, SetBrandState] = React.useState([]);
    const [productCount, SetProductCount] = React.useState([]);
    const [brandCheck, SetbrandCheck] = React.useState(false);
    const [productCountCheck, SetproductCountCheck] = React.useState(false);
    const [affiliateData, SetAffiliateData] = React.useState();
    const [addressState, SetAddressState] = React.useState();
    const [addressStateCheck, SetAddressStateCheck] = React.useState(false);
    const [productTableData, SetProductTableData] = React.useState();
    const [openAlert, setOpenAlert] = React.useState(true);
    const [atlantisCheckBox, setAtlantisCheckBox] = React.useState('');
    //Snackbars
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [typeSnackBar, setTypeSnackBar] = React.useState('info');
    const [textSnackBar, setTextSnackBar] = React.useState('An info message');
    const [contentChange, setContentChange] = React.useState(1);
    const [hcpSessionData, SethcpSessionData] = React.useState();
    const [announcement, SetAnnouncement] = React.useState();
    const [announcmentStatus, setAnnouncementstatus] = React.useState(false);
    const [checkBoxError, setCheckBoxError] = React.useState();
    const [productError, setProductError] = React.useState();
    const [forceUpdate, setforceUpdate] = React.useState(1);
    const [selectAddressError, setSelectAddressError] = React.useState();


    const setShowClosureHandler =(val) => {
        setShowClosure(val);
        setClosureMessage();
        setEnableRedirect(true);
    }
    const LoadHCPProducts = (affiliateId, hcpId, specialty) => {
        axios
            .get('/api/order/get', {
                // api/hcp/products/1/au-969hcp/Administration
                headers: {
                    URL: 'api/hcp/products/' + affiliateId + '/' + hcpId + '/' + 'hcpSps',
                },
            })
            .then((data) => {
                let products = Object.values(data.data.formProducts.products);
                SetProductTableData(data.data);
                if (products.length > 0) {
                    // let productsDict ={};
                    let productsArray = [];
                    let count = 0;
                    for (let i = 0; i < products.length; i++) {
                        let product = products[i];
                        let brand = new Brand();
                        brand.ID = product.brandId;
                        brand.Name = product.brandName;
                        brand.Products = product.Products;
                        //brand.UniqueCode = product.materialNo+'_'+product.affiliateId;
                        count = count + product.Products.length;
                        productsArray.push(brand);
                        console.log('------------products---------------', brand);
                    }
                    SetProductCount(count);

                    SetproductCountCheck(true);
                    console.log('------------products----------SetproductCountCheck-----', count);
                    SetBrandState(productsArray.sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0)));
                    console.log('-------set state----------SetBrandState--------', productsArray);
                    SetbrandCheck(true);
                } else {
                    setClosureMessage(
                        'Currently there are no products available. Please contact your Sales Representative by clicking the contact button above.'
                    );
                    setEnableCancelRedirect(true);
                    setEnableRedirect(false);
                    setShowClosure(true);
                }
            })
            .catch((err) => console.log('----LoadHCPProducts-------------err------------', err));
    };
    const LoadAffiliateInfo = (affiliateCode, _sessionData) => {
        axios
            .get('/api/admin/get', {
                // api/hcp/products/1/au-969hcp/Administration
                headers: {
                    URL: 'adminService/User/affiliate/' + affiliateCode,
                },
            })
            .then((data) => {
                let affiliate = data.data;
                GetHCPDetailsNew(_sessionData.CRMID, affiliate.affiliate_name);
                SetAffiliateData(affiliate);
                LoadHCPProducts(affiliate.affiliate_id, _sessionData.CRMID, _sessionData.Specialty);
                GetAnnouncement(affiliate.affiliate_id);
            })
            .catch((err) => console.log('----LoadAffiliateInfo-------------err------------', err));
    };
    const GetHCPDetailsNew = (query, affiliateCode) => {
        axios
            .get('/api/order/get', {
                headers: {
                    URL: 'api/hcpdata/GetHCPEPH/' + affiliateCode + '/' + query + '/200',
                },
            })
            .then((data) => {
                let hcps = data.data;
                if (hcps.length > 0) {
                    //let hcpList = [];
                    //for (let i = 0; i < hcps.length; i++) {
                    // for(let j=0;j< hcps[i].hcp_specialty.length;j++){
                    // let model = new HCPModel();
                    let hcp = hcps[0];
                    // model.ID = hcp.identifiers;
                    // model.Name = hcp.firstname + ' '+ hcp.lastname;
                    //let specialty = hcp.hcp_specialty[j];
                    // model.Speciality = specialty;
                    let _addresses = hcp.address; // name to be changed
                    let addressArray = [];
                    if (_addresses.length > 0) {
                        for (let k = 0; k < _addresses.length; k++) {
                            let address = _addresses[k];
                            let _address = new Address();

                            let addressLine = address.addressline.split(',');
                                    _address.Line1 = addressLine[0].trim();
                                    _address.Line2 = '';
                                    if(addressLine.length > 1){
                                        for (let index = 1; index < addressLine.length; index++) {
                                            const element = addressLine[index];
                                            _address.Line2 = _address.Line2 + (index != 1 ? ', ' : '') + element;
                                        }
                                    }
                                    _address.Line3 = address.city;
                                    _address.Line4 = address.state;
                                    _address.AreaCode = address.zip;
                            // let addressLine = address.addressline.split(' ');
                            // if (addressLine.length.length > 3) {
                            //     for (let l = 0; l < addressLine.length - 3; l++) {
                            //         let newAddressSplitted = addressLine[l];
                            //         _address.Line1 = _address.Line1 + newAddressSplitted;
                            //         //_address.Line2 = address.AddressLine2;
                            //     }
                            // } else {
                            //     _address.Line1 = address.addressline;
                            // }
                            // _address.AreaCode = address.zip;
                            // // _address.Line1 = address.AddressLine1;
                            // // _address.Line2 = address.AddressLine2;
                            // _address.Line3 =
                            //     address.city +
                            //     ' ' +
                            //     (addressLine.length > 2 ? addressLine[addressLine.length - 2] : address.state);
                            //_address.Line4 = address.country;
                            addressArray.push(_address);
                        }
                    }
                    SetAddressState(addressArray);
                    console.log('-------set state----------SetBrandState--------', addressArray);
                    SetAddressStateCheck(true);
                    //}
                    // }
                    // SetTempHcpList(hcpList);
                    //     SetHcpList(hcpList);
                } else {
                    // SetTempHcpList([]);
                    // SetHcpList([]);
                }
                // setHcpSearchStatusText(
                //     'Search Completed! Unable to find any HCPs. Please update filter condition and try again.'
                // );
            })
            .catch((err) => console.log('----------GetHCPDetailsNew--------err----------------------', err));
    };
    const LoadHCPAddress = (id, affiliateCode) => {
        axios
            .get('/api/order/get', {
                // api/hcp/products/1/au-969hcp/Administration
                headers: {
                    URL: 'OrderService/HCP/SearchHCP/' + id + '/' + affiliateCode,
                },
            })
            .then((data) => {
                let addressDetails = data.data[0].HCO_Data;
                if (addressDetails.length > 0) {
                    // let productsDict ={};
                    let addressArray = [];
                    // let count= 0;
                    for (let i = 0; i < addressDetails.length; i++) {
                        let address = addressDetails[i];
                        let _address = new Address();
                        _address.AreaCode = address.postal_code;
                        _address.Line1 = address.AddressLine1 + ' ' + address.AddressLine2;
                        _address.Line2 = address.AddressLine3 + ' ' + address.AddressLine4;
                        _address.Line3 = address.AddressLine5;
                        // count = count+ product.Products.length;
                        addressArray.push(_address);
                        console.log('------------address---------------', _address);
                    }

                    console.log('------------address----------SetproductCountCheck-----');
                    SetAddressState(addressArray);
                    console.log('-------set state----------SetBrandState--------', addressArray);
                    SetAddressStateCheck(true);
                }
            })
            .catch((err) => console.log('----LoadHCPAddress-------------err------------', err));
    };
    const GetAnnouncement = (affiliateId) => {
        axios
            .get('/api/admin/get', {
                // api/hcp/products/1/au-969hcp/Administration
                headers: {
                    URL: 'api/v1/announcement?status_flag=true&affiliate_id=' + affiliateId,
                },
            })
            .then((data) => {
                let announcements = data.data.data;
                console.log(announcements);
                let output = [];
                for (let i = 0; i < announcements.length; i++) {
                    let announcement = announcements[i];
                    let currentDate = new Date();

                    let todayDate = `${currentDate.getFullYear()}-${
                        (currentDate.getMonth() > 8 ? '' : '0') + (currentDate.getMonth() + 1)
                    }-${(currentDate.getDate() > 9 ? '' : '0') + currentDate.getDate()}`;
                    if (todayDate >= announcement.start_date && todayDate <= announcement.end_date) {
                        announcement.show = true;
                        output.push(announcement);
                    }
                }
                console.log(output);
                SetAnnouncement(output);
                setAnnouncementstatus(true);
            })
            .catch((err) => console.log('-----GetAnnouncement------err---------', err));
    };
    React.useEffect(() => {
        GetSessionInformation().then((data) => {
            console.log(data);
            const sessionData = JSON.parse(data.payload);
            SethcpSessionData(sessionData._data);
            console.log('-------------sessionData----------------------', sessionData);
            LoadAffiliateInfo(sessionData._data.CountryCode, sessionData._data);
            // GetHCPDetailsNew(sessionData._data.CRMID, sessionData._data.CountryCode);
            //  LoadHCPAddress(sessionData._data.CRMID, sessionData._data.CountryCode);
            setHcpName(() => {
                var sentence = sessionData._data.DisplayName.toLowerCase().trim().split(' ');
                for (var i = 0; i < sentence.length; i++) {
                    if (sentence[i]) sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
                }
                return sentence.join(' ');
            });
        });
    }, []);
    let history = useHistory();

    const forwardStates = {
        HCP: {
            Id: { get: hcpID, set: setHcpID },
            Name: { get: hcpName, set: setHcpName },
            Address: { get: hcpAddress, set: setHcpAddress },
        },
        OrderDetails: {
            get: orderDetails,
            set: setOrderDetails,
        },
        consent: {
            get: consentCheckBox,
            set: setconsentCheckBox,
        },
        atlantisConsent: {
            get: atlantisCheckBox,
            set: setAtlantisCheckBox,
        },
        Attachments: {
            get: attachments,
            set: setAttachments,
        },
    };
    const classes = useStyles();

    const fetchFormData = (formType) => {
        return;
    };
    const GetProductIds = () => {
        let brandIdArray = [];
        let orderArray = forwardStates.OrderDetails.get;
        for (let i = 0; i < orderArray.length; i++) {
            let order = orderArray[i];
            brandIdArray.push(order.Id);
            console.log('----------GetProductIds------------------------', order.Id);
        }
        return brandIdArray;
    };
    const submitData = () => {
        console.log('------------------forwardStates-------------------', forwardStates);
        let model = new SubmitModel();
        model.affiliate_id = affiliateData.affiliate_id;
        model.attachments = forwardStates.Attachments.get;
        model.created_by_user_id = hcpSessionData.CRMID;

        model.order_data = forwardStates.OrderDetails.get; //calc
        let _address = addressState[forwardStates.HCP.Address.get];
        model.hcp_address_object = _address;
        console.log(_address);
        model.hcp_address = _address.Line1 + ' ' + _address.Line2 + ' ' + _address.Line3 + ' ' + _address.AreaCode; //calc
        model.hcp_id = hcpSessionData.CRMID;
        model.atlantisCheckBox = forwardStates.atlantisConsent.get;
        model.hcp_name = forwardStates.HCP.Name.get;
        let productArray = Object.values(productTableData.product);
        console.log('-------Object.values(productTableData.product);---------------------------', productArray);
        let brandIdArray = GetProductIds();
        for (let i = 0; i < brandIdArray.length; i++) {
            let product = productArray.filter((v) => v.product_id == brandIdArray[i]);
            if (product != undefined && product != null) {
                console.log('---if(product!=undefined && product!=null)--------------------------------', product);
                let nameId = product[0].material_no + '_' + product[0].affiliate_id;
                model.product_array[nameId] = productTableData.product[nameId];
            }
        }
        //model.product_array = productTableData;//calc
        // model.created_on= forwardStates.HCP.Id;
        model.updated_by_user_id = hcpSessionData.CRMID;
        model.email = hcpSessionData.Email;
        model.Specialty = hcpSessionData.Specialty;
        // model.updated_on = forwardStates.Attachments;
        console.log('----------------------------------save model ', model);
        // return;
        axios
            .post('/api/order/post', model, {
                headers: {
                    URL: 'api/hcp/save/' + affiliateData.affiliate_name + '/' + affiliateData.Affiliate_code,
                },
            })
            .then((res) => {
                console.log('----------------User Save--------------', res.data);
                return res;
            })
            .catch((err) => {
                console.log('save Failed', err);
                return err;
            });
    };
    const handleClick = (type) => {
        switch (type) {
            case 'submit':
                let validated = true;
                if (!forwardStates.consent.get) {
                    setCheckBoxError(true);
                    validated = false;
                } else {
                    setCheckBoxError(false);
                }
                if (forwardStates.OrderDetails.get.length == 0) {
                    setProductError(true);
                    validated = false;
                } else {
                    setProductError(false);
                }
                console.log(forwardStates.HCP.Address.get);
                if (forwardStates.HCP.Address.get === '') {
                    setSelectAddressError(true);
                    validated = false;
                } else {
                    setSelectAddressError(false);
                }

                if (validated) {
                    setTextSnackBar('Form submitted successfully!');
                    setTypeSnackBar('success');
                    submitData();
                } else {
                    setTextSnackBar('Please fix highlighted errors!');
                    setTypeSnackBar('warning');
                }
                setOpenSnackBar(true);

                break;
            case 'error':
                setTextSnackBar('Form deleted!');
                setTypeSnackBar('error');
                setOpenSnackBar(true);
                break;
            case 'cancel':
                setShowClosure(true);
                // setTextSnackBar('Form deleted!');
                // setTypeSnackBar('warning');
                break;
            case 'save':
                setTextSnackBar('Form Saved in drafts!');
                setTypeSnackBar('info');
                setOpenSnackBar(true);
                break;
        }
    };
    const handelAnnouncementClose = (i) => {
        console.log(i);
        SetAnnouncement((pval) => {
            console.log(pval);

            let tempVal = pval;
            tempVal[i].show = false;
            return tempVal;
        });
        setforceUpdate((p) => p + 1);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
        if (typeSnackBar == 'success') history.replace('Home');
    };
    let from_server = {
        HCP: {
            Name: hcpSessionData,
            ID: hcpSessionData,
            Speciality: hcpSessionData,
            Address: addressState,
        },
        Products: {
            TotalCount: productCount,
            Brands: brandState,
        },
    };

    let stateCheck = !(
        addressStateCheck &&
        productCountCheck &&
        brandCheck &&
        announcmentStatus &&
        brandState.length > 0
    );
    return (
        <>
            <div className={styles.container}>
                {stateCheck ? (
                    <Skeleton.textField bgColor="#fff4e5" />
                ) : (
                    announcement.map((annon, i) => {
                        console.log(annon);
                        return (
                            <Collapse in={annon.show}>
                                <Alert
                                    color="warning"
                                    severity="info"
                                    onClose={() => {
                                        handelAnnouncementClose(i);
                                    }}>
                                    <AlertTitle>{annon.announcement_title}</AlertTitle>
                                    {annon.announcement_text}
                                </Alert>
                            </Collapse>
                        );
                    })
                )}
                <h3 className={classes.headerBottomPadding}>Initiate Sample Request</h3>
                {/* <Typography variant="subtitle1" display="block" gutterBottom>
        </Typography> */}
                <HCPDetails
                    hcpDetails={from_server.HCP}
                    states={forwardStates.HCP}
                    stateCheck={stateCheck}
                    enableCancelRedirect = {enableCancelRedirect}
                    error={selectAddressError}
                    hcpId={hcpSessionData}
                />
                {/* <ProductDetails
                    orderDetails={forwardStates.OrderDetails}
                    Products={from_server.Products}
                    stateCheck={stateCheck}
                    error={productError}
                    additionalCheckbox={forwardStates.atlantisConsent}
                /> */}
                <Consents error={checkBoxError} stateCheck={stateCheck} states={forwardStates.consent} />
                <OnClosureModal
                    showClosure={showClosure}
                    setShowClosure={setShowClosureHandler}
                    message={closureMessage}
                    enableRedirect ={enableRedirect}
                    redirectTo="Home"
                />
                <Grid container justify="center" alignContent="center">
                    {/* <Button
                        size="large"
                        className={styles.ButtonAlignCenter}
                        variant="contained"
                        onClick={() => handleClick('cancel')}
                        disabled={!enableCancelRedirect && (openSnackBar || stateCheck)}>
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        variant="contained"
                        onClick={() => handleClick('submit')}
                        className={`${styles.ButtonAlignCenter} ${styles.ColorbackgroundPrimary}`}
                        disabled={openSnackBar || stateCheck}>
                        Submit
                    </Button> */}
                </Grid>
                <Snackbar open={openSnackBar} autoHideDuration={2500} onClose={handleClose}>
                    <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={typeSnackBar}>
                        {textSnackBar}
                    </MuiAlert>
                </Snackbar>
            </div>
        </>
    );
};

export default mainForm;
