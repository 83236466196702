import React from 'react';
import ReactDOM from 'react-dom';
import uniqid from 'uniqid';

import {
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
  FormHelperText,
  FormControl,
  Input,
  InputLabel,
  TextField,
  Paper,
  NativeSelect,
  Hidden,
} from '@material-ui/core';
import styles from '../../../css/baseStyle.css';

const formSelectBlock = (props) => {
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState('');

  const stateValue = props.value ?? props.defaultValue;
  const stateSetValue = (value) => {
    if (props.isRequired == true && (value == null || value == '')) {
      setError(true);
      setHelperText(`${props.label} is required`);
    } else if (props.isRequired == true && value != null) {
      setHelperText('');
      setError(false);
    }

    props.setValue(value);
  };
  const generateDeviceControl = (props) => {
    console.log(props);
    if (props.deviceControl == null) {
      return (
        <FormControl
          variant="outlined"
          margin="dense"
          size="small"
          disabled={props.isDisabled ?? false}
          className={`${styles.GlobalFormControlInputDevice}`}
        >
          <InputLabel
            id={`${props.id ?? ''}_label`}
            disabled={props.isDisabled ?? false}
          >
            {props.label ?? 'No Label'}
          </InputLabel>
          <Select
            error={error || props.error}
            disabled={props.isDisabled ?? false}
            required={props.isRequired ?? false}
            value={stateValue}
            onChange={(event) => stateSetValue(event.target.value)}
            id={props.id ?? ''}
            defaultValue={props.defaultValue ?? ''}
            label={props.label ?? 'No Label'}
          >
            {props.MenuItems}
          </Select>
          <FormHelperText
            className={props.error ? styles.ColorTextPrimary : styles.blackText}
          >
            {props.helperText}
          </FormHelperText>
        </FormControl>
      );
    } else {
      return props.deviceControl;
    }
  };
  const generateControl = (props) => {
    if (props.control == null) {
      return (
        <FormControl size="small" className={styles.GlobalFormControlInput}>
          <Select
            error={error || props.error}
            disabled={props.isDisabled ?? false}
            required={props.isRequired ?? false}
            value={stateValue}
            variant="outlined"
            onChange={(event) => stateSetValue(event.target.value)}
            id={props.id ?? ''}
            defaultValue={props.defaultValue ?? ''}
          >
            {props.MenuItems}
          </Select>
          <FormHelperText
            className={props.error ? styles.ColorTextPrimary : styles.blackText}
          >
            {props.helperText}
          </FormHelperText>
        </FormControl>
      );
    } else {
      return props.control;
    }
  };
  return (
    <div>
      <Hidden smDown>
        <FormControlLabel
          id={props.id ?? ''}
          control={generateControl(props)}
          label={props.label ?? 'No Label'}
          labelPlacement="start"
          disabled={props.isDisabled ?? false}
          classes={{
            root: styles.GlobalFormControlAlignLeft,
            label: styles.GlobalFormControlLabel,
          }}
        />
      </Hidden>
      <Hidden mdUp>{generateDeviceControl(props)}</Hidden>
    </div>
  );
};

export default formSelectBlock;
