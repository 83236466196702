import {
  AppBar,
  Card,
  CardContent,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Accordion,
  FormControlLabel,
  Switch,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  Button,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import React from 'react';
import styles from '../../../../css/baseStyle';
import FormInputBlock from '../../../common/components/formInputBlock';
import FormSelectBlock from '../../../common/components/formSelectBlock';
import TablePaginationActions from '../../components/TablePaginationActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class Address {
  constructor() {
    this.Line1 = '';
    this.Line2 = '';
    this.Line3 = '';
    this.Line4 = '';
    this.AreaCode = '';
  }
}
class Brand {
  constructor() {
    this.Name = '';
    this.ID = '';
    this.Products = [];
  }
}
class HCPModel {
  constructor() {
    this.Name = '';
    this.ID = '';
    this.Profession = '';
    this.Speciality = '';
    this.addresses = [];
  }
}
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#D52B1E',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const customColumnStyle = { maxWidth: 40 };
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const HCPSearchDetails = (props) => {
  //States
  const [open, setOpen] = React.useState(false);
  const [displayModel, setDisplayModel] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [hcpHelperText, setHcpHelperText] = React.useState(
    'Customers can be searched by Name, ID or City'
  );
  const [hcpList, SetHcpList] = React.useState([]);
  const [hcpIdSearch, setHcpIdSearch] = React.useState('');
  const [hcpAddressSearch, setHcpAddressSearch] = React.useState([]);
  const [hcpaddressDropDown, setHcpaddressDropDown] = React.useState([]);
  const [hcpNameSearch, setHcpNameSearch] = React.useState('');
  const [hcpAddress, setHcpAddress] = React.useState('');
  const [specialitySearch, setSpecialitySearch] = React.useState('');
  const [postCodeSearch, setPostCodeSearch] = React.useState('');
  const [manualOrder, setManualOrder] = React.useState(false);
  const [hcpNameManual, setHcpNameManual] = React.useState('');
  const [hcpIdManual, setHcpIdManual] = React.useState('');
  const [organizationNameManual, setOrgnizationNameManual] = React.useState('');
  const [specialityManual, setSpecialityManual] = React.useState('');

  const forwardStates = {
    hcpID: {
      get: hcpIdSearch,
      set: setHcpIdSearch,
    },
    hcpName: {
      get: hcpNameSearch,
      set: setHcpNameSearch,
    },
    speciality: {
      get: specialitySearch,
      set: setSpecialitySearch,
    },
    postCode: {
      get: postCodeSearch,
      set: setPostCodeSearch,
    },
    Address: {
      get: hcpAddress,
      set: setHcpAddress,
    },
    Manual: {
      hcpID: {
        get: hcpIdManual,
        set: setHcpIdManual,
      },
      hcpName: {
        get: hcpNameManual,
        set: setHcpNameManual,
      },
      speciality: {
        get: specialityManual,
        set: setSpecialityManual,
      },
      orgName: {
        get: organizationNameManual,
        set: setOrgnizationNameManual,
      },
    },
  };
  const generateHCPAddressMenuList = (props) => {
    props.hcpDetails.DropDownAddress.set(() => {
      console.log(props, hcpAddressSearch);
      props.hcpDetails.ID.get == ''
        ? ''
        : hcpAddressSearch.map((address, i) => {
            return (
              <MenuItem key={i} value={i}>
                {address.Line1 +
                  ', ' +
                  address.Line2 +
                  ', ' +
                  address.Line3 +
                  ', ' +
                  address.Line4 +
                  '(' +
                  address.AreaCode +
                  ')'}
              </MenuItem>
            );
          });
    });
  };
  // React.useState(() => generateHCPAddressMenuList(props), [hcpAddressSearch]);

  // const [openHCPList, setOpenHCPList] = React.useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleModelOpen = () => {
    setDisplayModel(true);
  };

  const handleModelClose = () => {
    setDisplayModel(false);
  };
  const MergeProducts = (salesRepProducts, hcpProducts) => {
    let keys = Object.keys(hcpProducts);
    console.log('------------MergeProducts----------keys--------------', keys);
    let dictionary = []; // {};
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let brand = hcpProducts[key];
      console.log(
        '------------MergeProducts----------brand--------------',
        brand
      );
      let _brand = new Brand();
      _brand.ID = brand.ID;
      _brand.Name = brand.Name;
      for (let k = 0; k < brand.Products.length; k++) {
        for (let j = 0; j < salesRepProducts.length; j++) {
          //let check = false;
          let product = brand.Products[k];
          console.log(salesRepProducts);
          let salesRepBrandProducts = salesRepProducts[j].Products;
          let matchProduct = salesRepBrandProducts.filter(
            (v) => v.ID == product.ID
          );
          if (matchProduct.length > 0) {
            _brand.Products.push(matchProduct[0]);
            //  check=true;
          }
        }
      }
      if (_brand.Products.length > 0) {
        dictionary.push(_brand);
      }
    }
    return dictionary;
  };
  const UpdateProducts = (salesRepProducts, affiliateId, hcpId, specialty) => {
    axios
      .get('/api/order/get', {
        // api/hcp/products/1/au-969hcp/Administration
        headers: {
          URL:
            'api/hcp/products/' + affiliateId + '/' + hcpId + '/' + specialty,
        },
      })
      .then((data) => {
        // get keys xseparately And then loop through the dictiobar key values
        let products = Object.values(data.data.formProducts.products);
        //  SetProductTableData(data.data);
        if (products.length > 0) {
          // let productsDict ={};
          let productsArray = [];
          let count = 0;
          console.log(
            '-----------UpdateProducts------------salesRepProducts----------',
            salesRepProducts
          );
          for (let i = 0; i < products.length; i++) {
            let product = products[i];
            let brand = new Brand();
            brand.ID = product.brandId;
            brand.Name = product.brandName;
            brand.Products = product.Products;
            //brand.UniqueCode = product.materialNo+'_'+product.affiliateId;
            count = count + product.Products.length;
            productsArray.push(brand);
            console.log('------------products---------------', brand);
          }
          let mergedProduct = MergeProducts(salesRepProducts, productsArray);
          console.log(mergedProduct);
          props.modifyProductStates.set(mergedProduct);
          //  SetProductCount(count);
          //  SetproductCountCheck(true);
          console.log(
            '------------products----------SetproductCountCheck-----',
            props,
            mergedProduct
          );
          //  SetBrandState(productsArray);
          console.log(
            '-------set state----------SetBrandState--------',
            productsArray
          );
          // SetbrandCheck(true);
        }
      })
      .catch((err) =>
        console.log('----UpdateProducts-------------err------------', err)
      );
  };
  const handelHCPRowClick = (event, hcp) => {
    props.hcpDetails.Name.set(hcp.Name);
    props.hcpDetails.ID.set(hcp.ID);
    props.hcpDetails.Speciallity.set(hcp.Speciality);
    props.hcpDetails.orgName.set(hcp.Profession);
    setHcpAddressSearch(hcp.addresses);
    console.log('-----HCP---------------props-----------', props);
    UpdateProducts(
      props.modifyProductStates.get,
      props.session.get._currentUser.affiliate.affiliate_id,
      hcp.ID,
      hcp.Speciality
    );
    console.log(
      '-----------props.address.set(hcpList);--------------',
      props.address.get
    );

    props.hcpDetails.DropDownAddress.set(() => {
      const hcpItems = hcp.addresses.map((address, i) => {
        return (
          <MenuItem key={i} value={address}>
            {address.Line1 +
              ', ' +
              address.Line2 +
              ', ' +
              address.Line3 +
              ', ' +
              address.Line4 +
              '(' +
              address.AreaCode +
              ')'}
          </MenuItem>
        );
      });
      console.log(hcpItems);
      return hcpItems;
    });
    // generateHCPAddressMenuList(props);
    setDisplayModel(false);
  };

  const handelSubmit = () => {
    props.hcpDetails.Name.set(forwardStates.Manual.hcpName.get);
    props.hcpDetails.ID.set(forwardStates.Manual.hcpID.get);
    props.hcpDetails.Speciallity.set(forwardStates.Manual.speciality.get);
    props.hcpDetails.orgName.set(forwardStates.Manual.orgName.get);
    // generateHCPAddressMenuList(props);
    setDisplayModel(false);
  };
  const from_server = {
    HCPList: hcpList,
  };
  const fetchFormData = (formType) => {
    //FetchData using props.hcpDetails
    return;
  };
  const GetHCPDetails = (query, affiliateCode) => {
    console.log('----------query--------------------', query);
    console.log(
      '---------------affiliateCode------------------',
      affiliateCode
    );
    axios
      .get('/api/order/get', {
        headers: {
          URL: 'OrderService/HCP/SearchHCP/' + query + '/' + affiliateCode,
        },
      })
      .then((data) => {
        let hcps = data.data;
        console.log('-------hcp data-------------------', hcps);
        if (hcps.length > 0) {
          let hcpList = [];
          for (let i = 0; i < hcps.length; i++) {
            let model = new HCPModel();
            let hcp = hcps[i];
            model.ID = hcp.customer_id;
            model.Name = hcp.full_name;
            model.Profession = hcp.profession;
            model.Speciality = hcp.specialty;
            let _addresses = hcp.HCO_Data;
            if (_addresses.length) {
              for (let k = 0; k < _addresses.length; k++) {
                let address = _addresses[k];
                let _address = new Address();
                _address.AreaCode = address.postal_code;
                _address.Line1 = address.AddressLine1;
                _address.Line2 = address.AddressLine2;
                _address.Line3 =
                  address.AddressLine3 + ' ' + address.AddressLine4;
                _address.Line4 = address.AddressLine5;
                model.addresses.push(_address);
              }
            }

            hcpList.push(model);
          }
          SetHcpList(hcpList);

          handleModelOpen();
        }
      })
      .catch((err) => {
        console.log('-----------GetHCPDetails-----------------', err);
      });
  };
  const handleSearchOpen = () => {
    console.log(
      '---------handleSearchOpen----------------------------',
      props.hcpDetails.Name.get
    );
    if (hcpNameSearch.length < 4) {
      setHcpHelperText('Minimum 4 chars required to use Search.');
    } else {
      console.log(
        '---------props.salesRepDetails._currentUser-------------------------------',
        props.salesRepDetails._currentUser
      );
      // setHcpNameSearch(props.hcpDetails.Name.get);
      GetHCPDetails(
        hcpNameSearch,
        props.salesRepDetails._currentUser.affiliate.Affiliate_code
      );
      setHcpHelperText('Customers can be searched by Name, ID or City');
      setDisplayModel(true);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={styles.GlobalFormControlMaxWidth}>
      <Grid container>
        <Grid container style={{ width: '100%' }}>
          {props.Disabled || manualOrder ? (
            ''
          ) : (
            <>
              <Grid item xs={12} md={8}>
                <FormInputBlock
                  id="customerSearch"
                  label="Customer Search"
                  value={hcpNameSearch}
                  setValue={setHcpNameSearch}
                  helperText={hcpHelperText}
                  isDisabled={props.Disabled}
                  iconEnd={
                    <InputAdornment position="end">
                      <IconButton
                        disabled={props.Disabled}
                        onClick={handleSearchOpen}
                        edge="end"
                      >
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </>
          )}
          {props.Disabled || !(props.allowManualEntry ?? true) ? (
            ''
          ) : (
            <FormControlLabel
              control={
                <Switch
                  checked={manualOrder}
                  onChange={(events) => setManualOrder(events.target.checked)}
                  name="offlineOrderCheckbox"
                  color="primary"
                />
              }
              className={styles.ButtonAlignRight}
              label="Offline Order"
            />
          )}
          <Grid container style={{ width: '100%' }}>
            <Grid item xs={12} md={6}>
              <FormInputBlock
                id="hcpName"
                label="HCP Name"
                value={props.hcpDetails.Name.get}
                setValue={props.hcpDetails.Name.set}
                isDisabled={!manualOrder}
                isRequired={true}
                error={props.hcpNameError}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputBlock
                id="hcpId"
                label="HCP ID"
                value={props.hcpDetails.ID.get}
                setValue={props.hcpDetails.ID.set}
                isDisabled={!manualOrder}
                // isRequired={true}
              />
            </Grid>
            {props.hideProfessionandSpeciality ? (
              ''
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <FormInputBlock
                    id="orgName"
                    label="Profession"
                    value={props.hcpDetails.orgName.get}
                    setValue={props.hcpDetails.orgName.set}
                    isDisabled={!manualOrder}
                    // isRequired={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInputBlock
                    id="Speciality"
                    label="Specialty"
                    value={props.hcpDetails.Speciallity.get}
                    setValue={props.hcpDetails.Speciallity.set}
                    isDisabled={!manualOrder}
                    // isRequired={true}
                  />
                </Grid>
              </>
            )}
            {props.Disabled ||
            props.hideProfessionandSpeciality ||
            manualOrder ? (
              ''
            ) : (
              <Grid item xs={12}>
                <FormSelectBlock
                  label="HCP Address"
                  MenuItems={props.hcpDetails.DropDownAddress.get}
                  id="HCPaddress"
                  value={props.hcpDetails.Address.get}
                  setValue={props.hcpDetails.Address.set}
                  isDisabled={props.hcpDetails.ID.get == ''}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={displayModel}
        onClose={handleModelClose}
      >
        <AppBar className={`${styles.appBar} ${styles.ColorbackgroundPrimary}`}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleModelClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={styles.appBarTitle}>
              Search HCPs
            </Typography>
          </Toolbar>
        </AppBar>
        <Card className={styles.containerwithNavBar}>
          <CardContent>
            <Grid container style={{ width: '100%' }}>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="hcpIDSearch"
                  label="HCP ID"
                  value={forwardStates.hcpID.get}
                  setValue={forwardStates.hcpID.set}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="hcpNameSearch"
                  label="HCP Name"
                  value={forwardStates.hcpName.get}
                  setValue={forwardStates.hcpName.set}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="specialitySearch"
                  label="Specialty"
                  value={forwardStates.speciality.get}
                  setValue={forwardStates.speciality.set}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="areaCode"
                  label="Post Code"
                  value={forwardStates.postCode.get}
                  setValue={forwardStates.postCode.set}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <Button
                size="large"
                className={`${styles.ButtonAlignRight} ${styles.marginBottom}  ${styles.ColorbackgroundPrimary}`}
                variant="contained"
                onClick={() =>
                  GetHCPDetails(
                    forwardStates.hcpName.get,
                    props.salesRepDetails._currentUser.affiliate.Affiliate_code
                  )
                }
              >
                Search
              </Button>
            </Grid>

            {!manualOrder && (
              <>
                <Grid container className={styles.containerMargin}>
                  <Grid container item spacing={1} xs={6}></Grid>
                  <Grid
                    container
                    item
                    spacing={1}
                    xs={6}
                    direction="row-reverse"
                    justify="flex-start"
                    className={styles.containerRightPadding}
                  >
                    Total HCPs:{from_server.HCPList.length}
                  </Grid>
                </Grid>
                <TableContainer component={Paper}>
                  <Table
                    className={styles.table}
                    size="small"
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          HCP Name
                        </StyledTableCell>
                        <StyledTableCell align="center">HCP ID</StyledTableCell>
                        <StyledTableCell align="center">
                          Organization
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Specialty
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {from_server.HCPList.length > 0 ? (
                        (rowsPerPage > 0
                          ? from_server.HCPList.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : from_server.HCPList
                        ).map((hcp, i) => (
                          <StyledTableRow
                            key={i}
                            hover
                            onClick={(event) => handelHCPRowClick(event, hcp)}
                            // classes={{
                            //   hover: styles.tableRowHover,
                            // }}
                          >
                            <StyledTableCell align="center">
                              {hcp.Name}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {hcp.ID}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {hcp.Profession}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {hcp.Speciality ?? 0}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      ) : (
                        <TableRow className={styles.labelTextContainer}>
                          <TableCell>Loading HCPs...</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={from_server.HCPList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableContainer>
              </>
            )}
          </CardContent>
        </Card>
      </Dialog>
    </div>
  );
};

export default HCPSearchDetails;
