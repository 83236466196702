import React from 'react';
import PropTypes from 'prop-types';
import {
  GetSessionInformation,
  SetSessionInformationHCP,
} from '../../../Store/Slices/SessionSlice';
import { Grid, Typography } from '@material-ui/core';
import styles from '../../../css/baseStyle';
import LinkIcon from '@material-ui/icons/Link';
import MaterialLink from '@material-ui/core/Link';

const footerStyle = (props) => ({
  position: 'absolute',
  left: 0,
  bottom: 0,

  borderTop: '1px solid #e7e7e7',
  backgroundColor: '#f8f8f8',
  textAlign: 'center',
  paddingTop: '10px',
  width: '100%',
});

function Footer(props) {
  const [affiliateDetails, setAffiliateDetails] = React.useState();
  React.useState(() => {
    GetSessionInformation().then((data) => {
      const sessionData = JSON.parse(data.payload);
      const affiliate = sessionData._data.CountryCode;
      let footerDetails = {};
      if (affiliate.toUpperCase() == 'AU') {
        footerDetails = {
          disclaimer:
            'This website is intended for Australian Healthcare Professionals only.',
          copyRight:
            '©2024 Copyright Eli Lilly and Company. Eli Lilly Australia Pty Limited. ABN 39 000 233 992. Level 9; 60 Margaret Street, Sydney NSW 2000.',
          VVPM: 'Date of preparation: May 2024 | PP-LILLY-AU-0098',
          privacyPolicyLink: 'https://www.lillyprivacy.com/AU-en/hcp',
          termsOfUseLink: 'https://www.lilly.com.au/en/terms-of-use.aspx',
          contactUs: 'https://www.lilly.com.au/en/contact/index.aspx',
          accessibilityStatement:
            'https://www.lilly.com.au/accessibility-statement',
        };
      } else if (affiliate.toUpperCase() == 'NZ') {
        footerDetails = {
          disclaimer:
            'This website is intended for New Zealand Healthcare Professionals only.',
          copyRight:
            '©2024 Copyright Eli Lilly and Company. Eli Lilly and Company (NZ) Limited. NZBN: 9429039560643. Level 27, 88 Shortland Street, Auckland, 1010, New Zealand.',
          VVPM: 'Date of preparation: Month YYYY | PP-LILLY-NZ-0098',
          privacyPolicyLink: 'https://www.lillyprivacy.com/NZ-en/hcp',
          termsOfUseLink: 'https://www.lilly.co.nz/en/terms-of-use.aspx',
          contactUs: 'https://www.lilly.co.nz/en/contact/index.aspx',
          accessibilityStatement:
            'https://www.lilly.co.nz/accessibility-statement',
        };
      }
      setAffiliateDetails(footerDetails);
    });
  }, []);
  return (
    <footer style={footerStyle(props)}>
      {affiliateDetails == null ? (
        ''
      ) : (
        <>
          <Grid container className={styles.dashboardCardContent}>
            <MaterialLink
              variant="button"
              display="block"
              gutterBottom
              className={styles.alignCenterText}
              onClick={() =>
                (window.location.href = affiliateDetails.termsOfUseLink)
              }
            >
              Terms of Use
              <LinkIcon />
            </MaterialLink>
            <MaterialLink
              variant="button"
              display="block"
              gutterBottom
              className={styles.alignCenterText}
              onClick={() =>
                (window.location.href = affiliateDetails.privacyPolicyLink)
              }
            >
              Privacy Policy
              <LinkIcon />
            </MaterialLink>
            <MaterialLink
              variant="button"
              display="block"
              gutterBottom
              className={styles.alignCenterText}
              onClick={() =>
                (window.location.href = affiliateDetails.contactUs)
              }
            >
              Contact Us
              <LinkIcon />
            </MaterialLink>
            <MaterialLink
              variant="button"
              display="block"
              gutterBottom
              className={styles.alignCenterText}
              onClick={() =>
                (window.location.href = affiliateDetails.accessibilityStatement)
              }
            >
              Accessibility Statement
              <LinkIcon />
            </MaterialLink>
            <Grid container>
              <Typography
                variant="caption"
                gutterBottom
                className={styles.alignCenterText}
              >
                {affiliateDetails.copyRight}
              </Typography>
            </Grid>
            <Grid container>
              <Typography
                variant="caption"
                gutterBottom
                className={styles.alignCenterText}
              >
                {affiliateDetails.disclaimer}
              </Typography>
            </Grid>
            <Grid container>
              <Typography
                variant="caption"
                gutterBottom
                className={styles.alignCenterText}
              >
                {affiliateDetails.VVPM}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </footer>
  );
}

Footer.propTypes = {
  children: PropTypes.any,
};

export default Footer;
