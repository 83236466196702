import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AddCircleOutline } from '@material-ui/icons';

import HCPAddAddress from './HCPAddAddress';
import FormInputBlock from '../../common/components/formInputBlock';
import FormSelectBlock from '../../common/components/formSelectBlock';
import Skeleton from '../../common/components/Skeleton';
import styles from '../../../css/baseStyle.css';

import {
  TextField,
  Button,
  Typography,
  Modal,
  MenuItem,
  Select,
  Paper,
  Grid,
} from '@material-ui/core';

import { useHistory } from 'react-router-dom';

const HCPDetails = (props) => {
  let history = useHistory();
  const fetchFormData = (formType) => {
    return;
  };

  //States
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    window.location.replace('https://qa-lillystarterpacks.aws.lilly.com/AU/addAddress')
    // history.push('addAddress');
    // setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const generateHCPAddressMenuList = (props) => {
    return props.hcpDetails.Address.map((address, i) => {
      return (
        <MenuItem key={i} value={i}>
          {address.Line1 +
            ', ' +
            address.Line2 +
            ', ' +
            address.Line3 +
            '(' +
            address.AreaCode +
            ')'}
        </MenuItem>
      );
    });
  };

  return (
    <div>
      <h4>HCP Details </h4>
      <Paper elevation={4} className={styles.containerPaper}>
        <Grid container>
          <Grid item xs={12} md={6}>
            {props.stateCheck ? (
              <Skeleton.textField />
            ) : (
              <FormInputBlock
                label="HCP Name"
                value={props.states.Name.get}
                setValue={props.states.Name.set}
                isDisabled={true}
              />
            )}
          </Grid>
          {/* <Grid item xs={12}>
            {props.stateCheck ? (
              <Skeleton.textField />
            ) : (
              <FormSelectBlock
                label="HCP Address"
                MenuItems={generateHCPAddressMenuList(props)}
                id="HCPaddress"
                value={props.states.Address.get}
                setValue={props.states.Address.set}
                error={props.error}
                helperText={
                  props.error ? 'Please select Delivery address!' : ''
                }
              />
            )}
          </Grid> */}
          <Grid container item xs={12}>
            <Typography variant="subtitle2" className={styles.textAlignleft}>
              {/* If your address is not listed or if you have special instructions
              for your delivery please contact your Lilly sales representative. */}
              {/* Disabling address module */}
              We regret to inform you that Lilly's Samples Self-Ordering Service has been discontinued.
               To request samples, kindly click on the "Contact Your Rep" button below, 
               and our team will be pleased to assist you with your sample needs. 
            </Typography>
            {/* <div className={`${styles.flexButtonContent}`} onClick={handleOpen}>
              <div className="about-page-content" onClick={handleOpen}>
                Contact your Lilly Sales Representative
              </div>
            </div> */}
            <Grid >
              {/* <OnClosureModal showClosure={showClosure} setShowClosure={setShowClosure} redirectTo="../HCP" /> */}
              {/* <Button
                size="large"
                className={styles.ButtonAlignCenter}
                variant="contained"
                onClick={handleOpen}>
                Cancel
              </Button> */}
              <Button
                size="large"
                variant="contained"
                onClick={handleOpen}
                className={`${styles.ButtonAlignRight} ${styles.ColorbackgroundPrimary}`}>
                Contact your Lilly Sales Representative
              </Button>
            </Grid>
            {/* <Button
              size="large"
              variant="contained"
              className={`${styles.ButtonAlignRight}  ${styles.ColorbackgroundPrimary}`}
              onClick={handleOpen}
              disabled={props.stateCheck}
            >Contact your Lilly Sales Representative */}
            {/* <div className={`${styles.flexButtonContent}`} onClick={handleOpen}>
                <div className={styles.addressButtonText}>
                  Contact your Lilly Sales Representative
                </div>
              </div> */}
            {/* <div className={`${styles.flexButtonContent}`} onClick={handleOpen}>
                <div className={styles.addressButtonText} onClick={handleOpen}>
                  Contact your Lilly Sales Representative
                </div>
            </div> */}
            {/* </Button> */}
            {/* <Modal open={open} onClose={handleClose}>
              <HCPAddAddress />
            </Modal> */}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default HCPDetails;
