import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    FormControlLabel,
    Switch,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../../../../css/baseStyle.css';
import FormInputBlock from '../../../common/components/formInputBlock';
import OnClosureModal from '../../../common/components/onClosureModal';
import axios from 'axios';
import slice, { GetSessionInformation } from '../../../../Store/Slices/SessionSlice';

class HCPMailModel {
    constructor() {
        this.hcp_name = '';
        this.lilly_email = '';
        this.hcp_veeva_id = '';
        this.hcpMail = '';
        this.phoneNumber = '';
        this.countryCode = '';
        this.repName = '';
    }
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const addNewAddress = (props) => {
    //#region componenets states2
    const [hcpName, setHcpName] = React.useState('');
    const [hcpID, setHcpID] = React.useState('');
    const [hcpEmail, setHcpEmail] = React.useState('');
    const [hcpPhoneNumber, setHcpPhoneNumber] = React.useState('');
    const [consentCheckBox, setconsentCheckBox] = React.useState(false);
    const [enablePhoneField, setEnablePhoneField] = React.useState(false);

    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [typeSnackBar, setTypeSnackBar] = React.useState('info');
    const [textSnackBar, setTextSnackBar] = React.useState('An info message');
    const [contentChange, setContentChange] = React.useState(1);
    const [showClosure, setShowClosure] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [salesRepdata, SetsalesRepdata] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const [hcpSession, SetHCPSession] = React.useState({});
    let history = useHistory();

    //#endregion
    //#region formStates
    const forwardStates = {
        Id: { get: hcpID, set: setHcpID },
        Name: { get: hcpName, set: setHcpName },
        Email: { get: hcpEmail, set: setHcpEmail },
        Number: { get: hcpPhoneNumber, set: setHcpPhoneNumber },
        CheckBox: { get: consentCheckBox, set: setconsentCheckBox },
    };
    const SendMailToRep = () => {
        let model = new HCPMailModel();
        model.countryCode = hcpSession._data.CountryCode;
        model.hcpMail = hcpSession._data.Email;
        model.lilly_email = from_server.SalesRep.Name[0].email;
        model.hcp_veeva_id = hcpSession._data.CRMID;
        model.phoneNumber = hcpPhoneNumber;
        model.hcp_name = hcpSession._data.DisplayName;
        model.repName = from_server.SalesRep.Name[0].name;
        axios
            .post('/api/order/post', model, {
                // api/hcp/products/1/au-969hcp/Administration
                headers: {
                    URL: 'api/hcp/SendAddressAdditionMail',
                },
            })
            .then((data) => console.log('-------SendMailToRep---------data----------------', data.data))
            .catch((err) => console.log('---------SendMailToRep---------err------', err));
    };
    //#endregion
    const handleClick = (type) => {
        switch (type) {
            case 'submit':
                SendMailToRep();
                setOpen(true);
                break;
            case 'Cancel':
                setShowClosure(true);
                break;
        }
    };

    const handleClose = (actionType) => {
        setOpen(false);
        if (actionType == 'yes') {
            history.replace('../home');
        }
    };
    const GetSalesRepDetailsByGlobalIds = (globalIds) => {
        axios
            .post('/api/admin/post', globalIds, {
                // api/hcp/products/1/au-969hcp/Administration
                headers: {
                    URL: 'adminService/User/GetWorkerDataByGLobalIds',
                },
            })
            .then((data) => {
                let salesReps = data.data;
                if (salesReps.length > 0) {
                    let salesRepArray = [];
                    SetsalesRepdata(salesReps);
                    for (let i = 0; i < salesReps.length; i++) {}
                    setShow(true);
                }
            })
            .catch((err) => console.log('------GetSalesRepDetailsByGlobalIds-----------err-----------', err));
    };
    const GetSalesRepGlobalIds = (hcpId) => {
        axios
            .get('/api/order/get', {
                // api/hcp/products/1/au-969hcp/Administration
                headers: {
                    URL: 'api/hcp/GetSalesRepGlobalIdsByHCPId/' + hcpId,
                },
            })
            .then((data) => {
                let globalIds = data.data;
                GetSalesRepDetailsByGlobalIds(globalIds);
            })
            .catch((err) => console.log('-----GetSalesRepGlobalIds----------err-----------', err));
    };
    const from_server = {
        SalesRep: {
            Name: salesRepdata,
            Number: salesRepdata,
        },
    };
    React.useEffect(() => {
        GetSessionInformation().then((data) => {
            console.log(data);
            const sessionData = JSON.parse(data.payload);
            console.log(sessionData);
            GetSalesRepGlobalIds(sessionData._data.CRMID);

            SetHCPSession(sessionData);
            setHcpName(() => {
                var sentence = sessionData._data.DisplayName.toLowerCase().trim().split(' ');
                for (var i = 0; i < sentence.length; i++) {
                    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
                }
                return sentence.join(' ');
            });

            setHcpEmail(sessionData._data.Email);
        });
    }, []);

    return (
        <>
            {!show ? (
                ''
            ) : (
                <div>
                    <h4>Contact Details</h4>
                    <Typography variant="subtitle1" display="block" gutterBottom>
                        Samples must be delivered to a verified medical business address. A Lilly representative will
                        contact you shortly to assist with your order using the email address from your Lilly Passport
                        account. Please provide a direct phone line as an alternative contact method.
                    </Typography>
                    <Typography variant="subtitle2" display="block" gutterBottom>
                        If your order is urgent. Please contact : {from_server.SalesRep.Name[0].name}:{' '}
                        {from_server.SalesRep.Number[0].cellPhoneNumber == null
                            ? from_server.SalesRep.Number[0].workPhoneNumber
                            : from_server.SalesRep.Number[0].cellPhoneNumber}
                    </Typography>
                    <Grid item xs={12} md={8}>
                        <FormInputBlock
                            label="Email"
                            value={forwardStates.Email.get}
                            //   setValue={setHcpEmail}
                            isDisabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={enablePhoneField}
                                    onChange={(events) => {
                                        !events.target.checked ? setHcpPhoneNumber('') : '';
                                        setEnablePhoneField(events.target.checked);
                                    }}
                                    name="providePhoneNumber"
                                    color="primary"
                                    classes={{
                                        root: styles.alignSwitch,
                                    }}
                                />
                            }
                            label="Provide Phone Number"
                            labelPlacement="start"
                        />
                    </Grid>
                    {enablePhoneField && (
                        <Grid item xs={12} md={8}>
                            <FormInputBlock
                                label="Phone Number"
                                value={hcpPhoneNumber}
                                setValue={setHcpPhoneNumber}
                                type="number"
                            />
                        </Grid>
                    )}
                    <Grid container justify="center" alignContent="center">
                        <OnClosureModal showClosure={showClosure} setShowClosure={setShowClosure} redirectTo="../HCP" />
                        <Button
                            size="large"
                            className={styles.ButtonAlignCenter}
                            variant="contained"
                            onClick={() => handleClick('Cancel')}>
                            Cancel
                        </Button>
                        <Button
                            size="large"
                            variant="contained"
                            onClick={() => handleClick('submit')}
                            className={`${styles.ButtonAlignCenter} ${styles.ColorbackgroundPrimary}`}>
                            Submit
                        </Button>
                    </Grid>

                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        //aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description">
                        {/* <DialogTitle id="alert-dialog-slide-title">
          {'Submitted succesfully!!'}
        </DialogTitle> */}
                        <DialogTitle id="alert-dialog-slide-description">
                            Your request has been received and your Sales Representative will be in contact soon.
                        </DialogTitle>
                        <DialogActions>
                            {/* <Button onClick={() => handleClose('no')} color="primary">
            No
          </Button> */}
                            <Button onClick={() => handleClose('yes')} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </>
    );
};

export default addNewAddress;
