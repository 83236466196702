import React from 'react';
import ReactDOM from 'react-dom';

import styles from '../../../css/baseStyle.css';
import FormInputBlock from '../../common/components/formInputBlock';
import MuiAlert from '@material-ui/lab/Alert';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import OnClosureModal from '../../common/components/onClosureModal';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Typography,
  Paper,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogActions,
  Chip,
  Slide,
  Grid,
  Checkbox,
  StylesProvider,
  Snackbar,
  Hidden,
} from '@material-ui/core';
// import AuditTrailTable from '../components/AuditTrail';

const listofChips = (props) => {
  console.log(props);
  if (props.states.Attachments.get.length > 0) {
    return (
      <div>
        {console.log('inside if')}
        {props.states.Attachments.get.map((attachment) => {
          return (
            <Chip
              label={attachment}
              onClick={handleAttachmentClick}
              onDelete={handleAttachmentDelete}
              className={styles.formAttachmentsChips}
            />
          );
        })}
      </div>
    );
  } else {
    return <p>No Attachments to display</p>;
  }
};
const handleAttachmentDelete = () => {
  console.info('You clicked the delete icon.');
};

const handleAttachmentClick = () => {
  console.info('You clicked the Chip.');
};

const approverTemplate = (props) => {
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [typeSnackBar, setTypeSnackBar] = React.useState('info');
  const [textSnackBar, setTextSnackBar] = React.useState('An info message');
  const [contentChange, setContentChange] = React.useState(1);
  const [showClosure, setShowClosure] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogText, setDialogText] = React.useState();
  // const [approverComments, setApproverComments] = React.useState('');

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  let history = useHistory();
  const handleDialogClose = (actionType) => {
    setDialogOpen(false);
    window.location =
      'https://www.lilly.com.au/our-medicines/current-medicines';
    // close();
  };
  const handleClick = (type) => {
    switch (type) {
      case 'approve':
        props.toApprove();
        setDialogText('Order approved. Please click Ok to close the window.');
        setDialogOpen(true);
        // setTextSnackBar('Form Approved successfully!');
        // setTypeSnackBar('success');
        // setOpenSnackBar(true);

        break;
      case 'cancel':
        setShowClosure(true);
        break;
      case 'reject':
        props.toReject();
        // if (props.states.ApproverComments.get < 5) {
        setDialogText('Order rejected. Please click Ok to close the window.');
        setDialogOpen(true);
        // setOpenSnackBar(true);
        // } else {
        //   props.toReject();
        //   setTextSnackBar('Form Rejected!');
        //   setTypeSnackBar('warning');
        //   setOpenSnackBar(true);
        // }
        break;
      case 'resubmit':
        if (props.states.ApproverComments.get < 5) {
          setTextSnackBar('Comment is required while requesting to resubmit.');
          setTypeSnackBar('error');
          setOpenSnackBar(true);
        } else {
          props.submit('Resubmit');
          setTextSnackBar('Form updated for resubmission!');
          setTypeSnackBar('warning');
          setOpenSnackBar(true);
        }
        break;
      case 'error':
        setTextSnackBar('An error occured while submission!');
        setTypeSnackBar('error');
        setOpenSnackBar(true);
        break;
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
    if (typeSnackBar == 'success' || typeSnackBar == 'warning')
      history.replace('../../../Home');
  };

  const generateApproverSection = (props) => {
    return props.approvers.map((approver, j) => {
      if (
        approver.Approved == 'Approved' ||
        approver.LillyID != props.baseDetails.ID
      ) {
        return (
          <Paper elevation={4} className={styles.containerPaper}>
            <Grid item xs={12} md={6}>
              <FormInputBlock
                label="Approver"
                value={approver.Name}
                // setValue={setApproverComments}
                isRequired={true}
                isDisabled={true}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormInputBlock
                label="Approver Comments"
                value={approver.comment}
                // setValue={setApproverComments}
                isRequired={true}
                isDisabled={true}
                multiline={true}
                rowsMax={4}
              />
            </Grid>
          </Paper>
        );
      } else {
        return (
          <Paper elevation={4} className={styles.containerPaper}>
            <Grid item xs={12} md={12}>
              <FormInputBlock
                label="Approver"
                value={approver.Name}
                // setValue={setApproverComments}
                isRequired={true}
                isDisabled={true}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormInputBlock
                label="Approver Comments"
                value={props.states.ApproverComments.get}
                setValue={props.states.ApproverComments.set}
                multiline={true}
                rowsMax={4}
              />
            </Grid>
          </Paper>
        );
      }
    });
  };

  return (
    <div>
      <Grid container className={styles.formTemplateMarginBottom}>
        <Grid item xs={12} md={6}>
          <FormInputBlock
            label="Form ID"
            defaultValue={props.baseDetails.FormID ?? 'New Submission'}
            isDisabled={true}
            isRequired={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInputBlock
            label="Submitter Lilly ID"
            defaultValue={props.baseDetails.ID}
            isDisabled={true}
            isRequired={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInputBlock
            label="Submitter Name"
            defaultValue={props.baseDetails.Name}
            isRequired={true}
            isDisabled={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInputBlock
            label="Submitter Email"
            defaultValue={props.baseDetails.Email}
            isRequired={true}
            isDisabled={true}
          />
        </Grid>
        {props.baseDetails.DSM != null ? (
          <>
            <Grid item xs={12} md={6}>
              <FormInputBlock
                label="DSM"
                defaultValue={props.baseDetails.DSM}
                isRequired={true}
                isDisabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} />
          </>
        ) : (
          ''
        )}
        {/* <Grid item xs={12} md={3}>
          <Button
            component="label"
            variant="contained"
            color="default"
            startIcon={<CloudUploadIcon />}
          >
            Upload Attachments
            <input
              hidden
              id="attachMentTemplate"
              multiple
              type="file"
              onChange={(events) => {
                var fileNameStore = [];
                var initialFiles = props.states.Attachments.get;
                Array.from(events.target.files).forEach((file) =>
                  fileNameStore.push(file.name)
                );
                console.log(initialFiles);
                props.states.Attachments.set(
                  initialFiles.concat(fileNameStore)
                );
                setContentChange(contentChange + 1);
              }}
            />
          </Button>
        </Grid>
        <Grid item xs={12} md={9}>
          {listofChips(props)}
        </Grid> */}
      </Grid>

      <div>{props.children}</div>

      {/* {generateApproverSection(props)} */}
      {/*<Paper elevation={4} className={styles.containerPaper}>
         <Grid container className={styles.containerMargin}>
          <Grid item xs={12}>
            <FormInputBlock
              label="Comment"
              value={props.states.Comment.get}
              setValue={props.states.Comment.set}
              multiline={true}
              // isRequired={true}
              // isDisabled={true}
            />
          </Grid>
        </Grid> 
      </Paper>*/}
      <Grid container justify="center" alignContent="center">
        <OnClosureModal
          showClosure={showClosure}
          setShowClosure={setShowClosure}
          redirectTo="../../Home"
        />
        <Button
          size="large"
          className={styles.ButtonAlignCenter}
          variant="contained"
          onClick={() => handleClick('reject')}
          disabled={openSnackBar}
        >
          Reject
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() => handleClick('approve')}
          className={`${styles.ButtonAlignCenter} ${styles.ColorbackgroundPrimary}`}
          disabled={openSnackBar}
        >
          Approve
        </Button>
      </Grid>
      {/* <AuditTrailTable auditTrail={props.auditTrail} /> */}

      <Snackbar
        open={openSnackBar}
        autoHideDuration={2500}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={typeSnackBar}
        >
          {textSnackBar}
        </MuiAlert>
      </Snackbar>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        //aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle id="alert-dialog-slide-title">
          {'Submitted succesfully!!'}
        </DialogTitle> */}
        <DialogTitle id="alert-dialog-slide-description">
          {dialogText}
        </DialogTitle>
        <DialogActions>
          {/* <Button onClick={() => handleClose('no')} color="primary">
            No
          </Button> */}
          <Button onClick={() => handleDialogClose('yes')} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default approverTemplate;
