import { openDB } from 'idb/with-async-ittr.js';

const _dataBaseName = 'samplePortalIndexedDB';
const _productTable = 'products';
const _orderTable = 'orders';

const initializeDB = async () => {
  try {
    const db = await openDB(_dataBaseName, 1, {
      upgrade(db) {
        // Create a store of objects
        const products = db.createObjectStore(_productTable, {
          // The 'id' property of the object will be the key.
          keyPath: 'id',
          // If it isn't explicitly set, create a value by auto incrementing.
          autoIncrement: true,
        });
        products.createIndex('product_id', 'product_id');
        const orders = db.createObjectStore(_orderTable, {
          // The 'id' property of the object will be the key.
          keyPath: 'id',
          // If it isn't explicitly set, create a value by auto incrementing.
          autoIncrement: true,
        });
        //   // Create an index on the order_id property of the objects.
        orders.createIndex('order_id', 'order_id');
      },
    });
    return db;
  } catch (err) {
    console.log(err);
  }
};

//#region Products ops
const addProduct = async (productJson) => {
  initializeDB().then(async (db) => {
    await db.add(_productTable, productJson);
    return 'sucess';
  });
};

const bulkAddProducts = async (productJsonArr) => {
  initializeDB().then(async (db) => {
    const tx = db.transaction(_productTable, 'readwrite');
    await Promise.all(
      productJsonArr.map((productJSON) => tx.store.add(productJSON))
    );
    return 'sucess';
  });
};

const getAllProducts = async () =>
  await initializeDB().then(db.getAllFromIndex(_productTable, 'product_id'));

export const Product = {
  add: addProduct,
  addBulk: bulkAddProducts,
  getAll: getAllProducts,
};
//#endregion

//#region Order Ops
const addOrder = async (orderJson) => {
  initializeDB().then(async (db) => {
    await db.add(_orderTable, orderJson);
    return 'sucess';
  });
};

// Get all the items:
const getAllOrder = async () =>
  await initializeDB().then(db.getAllFromIndex(_orderTable, 'order_id'));

export const Order = {
  add: addOrder,
  getAll: getAllOrder,
};

//#endregion

//   // Add 'And, happy new year!' to all articles on 2019-01-01:

//     const tx = db.transaction('articles', 'readwrite');
//     const index = tx.store.index('date');

//     for await (const cursor of index.iterate(new Date('2019-01-01'))) {
//       const article = { ...cursor.value };
//       article.body += ' And, happy new year!';
//       cursor.update(article);
//     }

//     await tx.done;
