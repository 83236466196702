import {
  Backdrop,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React from 'react';
import styles from '../../css/baseStyle';
import { useHistory } from 'react-router-dom';
import { SetSessionInformationHCP } from '../../Store/Slices/SessionSlice';
const AuthoriseHCP = (props) => {
  const [statusText, setStatusText] = React.useState('Fetching Details');
  const [dots, setDots] = React.useState('.');
  const history = useHistory();
  React.useEffect(() => {
    const timer = setInterval(() => {
      setDots((prevDots) => (prevDots.length >= 5 ? '.' : prevDots + '.'));
    }, 200);
    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    
    let token = window.location.search.split('=')[1].split("&")[0];
    // console.log('Token : ', token);
    console.log('Props : ', props);
    console.log(window.location)
    if(token !== undefined){
      console.log( 'Token : ', token )
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      const hcpDetails = JSON.parse(jsonPayload)
      console.log(hcpDetails.email, hcpDetails.countryCode, JSON.parse(jsonPayload))
      // let email = 'testspainhcp@gmail.com'
      axios
        .get("/api/order/get", {
          // api/hcp/products/1/au-969hcp/Administration
          headers: {
            URL: `OrderService/HCP/passportVerifyToken/${hcpDetails.email}/${token}`,
          },
        })
        .then((data) => {
          console.log(data);
          if (data.data.status == "Success") {
            console.log('---Token Verified Success')
            history.replace(`/${hcpDetails.countryCode}/hcp`);
            
            axios
              .get("/api/admin/get", {
                headers: { URL: "hcp/" + token },
              })
              .then((data) => {
                console.log("Data : ", data);
                // set state variable here = data.data;
                let crmId;
                let identifiers =
                  data.data.additionalData.passportPayload.profile.Identifiers;
                if (identifiers.length > 0) {
                  for (let i = 0; i < identifiers.length; i++) {
                    if (identifiers[i].Type.includes("Customer ODS")) {
                      crmId = identifiers[i].ID;
                    }
                  }
                }
                let SessionData = {
                  CRMID: crmId,
                  DisplayName: data.data.userName,
                  Email: data.data.email,
                  CountryCode:
                    data.data.additionalData.passportPayload.profile.CountryCode,
                  ProfessionalGroup:
                    data.data.additionalData.tokenPayload.profession,
                  Specialty: data.data.additionalData.tokenPayload.specialty,
                };
                console.log("====================", SessionData.CountryCode);
                setStatusText("Initiating Session");
                SetSessionInformationHCP(SessionData)
                .then((data) => {
                  console.log("====================", SessionData.CountryCode);
                  props.setAffiliateCode(SessionData.CountryCode);
                  // history.replace(`/` + SessionData.CountryCode + `/hcp`);
                });
              })
              .catch((err) => console.log(err));
          } else {
            console.log('---not verified token')
            history.replace(`/SelectAffiliate`);
          }
        })
        .catch((error) => console.log(error));
    }
  
    }, [])

  return (
    <div>
      <Backdrop open={true}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item sm={12}>
            <CircularProgress
              color="inherit"
              classes={{ root: styles.svgCenter }}
            />
          </Grid>
          <Grid item sm={12}>
            <Typography variant="h5" align="center" gutterBottom>
              {statusText + dots}
            </Typography>
          </Grid>
        </Grid>
      </Backdrop>
    </div>
  );
};
export default AuthoriseHCP;
