
import {configureStore , createStore} from '@reduxjs/toolkit';
import sessionReducer from './Slices/SessionSlice';
import rootReducer from './Reducer';

export default createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({
    latency: 0
  }));

const earlierStore=  configureStore({
    reducer : {
        session : sessionReducer
    }
})
