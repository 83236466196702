import { withStyles } from '@material-ui/core/styles';

import { TableRow, TableCell } from '@material-ui/core';
export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#D52B1E',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
