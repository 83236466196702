import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import slice from '../../Store/Slices/SessionSlice';
import { store } from '../../Store/store';
import { createAction } from 'redux-actions';
import {
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
  FormHelperText,
  Input,
  Typography,
  InputLabel,
  Paper,
  Hidden,
} from '@material-ui/core';
import styles from '../../css/baseStyle.css';
const footerStyle = (props) => ({
  position: 'absolute',
  left: 0,
  bottom: 0,

  borderTop: '1px solid #e7e7e7',
  backgroundColor: '#f8f8f8',
  textAlign: 'center',
  paddingTop: '10px',
  width: '100%',
});

const SelectAffiliate = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const login = createAction('Login');

  const divStyle = {
    backgroundImage:
      'url(./public/img/bundle/LifeMoments_Healthcare_0519_offset_634107.jpg)',
  };

  const affiliateChangeHandler = (event) => {
    props.setAffiliateCode(event.target.value);
    let urlRedirect = window.location.protocol+'//'+window.location.host+'/AuthoriseHCP';
    //history.push('./' + event.target.value + '/');
    window.location.replace(
      `https://account.lilly.com/en-${event.target.value}/signin?token_required&title=Start%20Right&url=`+ urlRedirect
    );
    //  Regions ----------------------VNS-------------------
  };

  const fromServer = {
    Affilaites: [
      //{ ID: 1, Code: 'IN', Name: 'India' },
      { ID: 2, Code: 'AU', Name: 'Australia' },
      { ID: 3, Code: 'NZ', Name: 'New Zealand' },
    ],
  };
  return (
    <div>
      <Grid container>
        <Hidden smDown>
          <Grid item md={6}>
            <div className={styles.LoginBackground} style={divStyle}></div>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={6}>
          <Hidden mdUp>
            <div
              className={styles.LoginBackgroundSmallScreen}
              style={divStyle}
            ></div>
          </Hidden>
          <Paper elevation={0} className={styles.LoginScreenPaper}>
            <div className={styles.LoginVerticalCenter}>
              <img
                src="./public/img/bundle/LillyLogo_RGB_Red.jpg"
                alt="Lilly"
                className={styles.LoginLogoStyle}
              />
              <h2 className={styles.CenterText}>Start Right</h2>
              <Grid container>
                <FormControlLabel
                  control={
                    <Select
                      id="selectAffiliate"
                      variant="outlined"
                      defaultValue={''}
                      onChange={(event) => affiliateChangeHandler(event)}
                      className={styles.FormControlInput}
                    >
                      {fromServer.Affilaites.map((affiliate, i) => {
                        return (
                          <MenuItem key={affiliate.ID} value={affiliate.Code}>
                            {affiliate.Name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  label="Select Location"
                  labelPlacement="start"
                  classes={{
                    root: styles.FormControlAlignCenter,
                    label: styles.FormControlLabel,
                  }}
                />
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <footer style={footerStyle(props)}>
        <Grid container className={styles.dashboardCardContent}>
          <Grid container>
            <Typography
              variant="caption"
              gutterBottom
              className={styles.alignCenterText}
            >
              @2024 Copyright Eli Lilly and Company Eli Lilly Australia Ply Limited.
            </Typography>
          </Grid>
          <Grid container>
            <Typography
              variant="caption"
              gutterBottom
              className={styles.alignCenterText}
            >
              ABN 39 000 233 992. Level 9; 60 Margaret Street, Sydney NSW 2000.
            </Typography>
          </Grid>
          <Grid container>
            <Typography
              variant="caption"
              gutterBottom
              className={styles.alignCenterText}
            >
              <a href="https://www.lilly.com.au/terms-of-use">Terms of Use</a> | <a href="https://www.lillyprivacy.com/AU-en/hcp">Privacy Policy</a> | <a href="https://www.lilly.com.au/contact">Contact</a> | <a href="https://www.lilly.com.au/accessibility-statement">Accessibility Statement</a>
            </Typography>
          </Grid>
          <Grid container>
            <Typography
              variant="caption"
              gutterBottom
              className={styles.alignCenterText}
            >
              This website is intended for Australian and New Zealand Healthcare Professionals only.
            </Typography>
          </Grid>
          <Grid container>
            <Typography
              variant="caption"
              gutterBottom
              className={styles.alignCenterText}
            >
              Date of preparation: May 2024 |PP-LILLY-AU-0098.
            </Typography>
          </Grid>
        </Grid>
      </footer>
    </div>
  );
};

export default SelectAffiliate;
