import {combineReducers} from 'redux';
import sessionSlice from './Slices/SessionSlice';


const rootReducer = combineReducers({
    session : sessionSlice
});

export default rootReducer;


