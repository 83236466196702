import React from 'react';
import { Skeleton } from '@material-ui/lab';
function range(start, end) {
  if (start === end) return [start];
  return [start, ...range(start + 1, end)];
}
const _animation_type = 'pulse';
const Table = (props) => {
  return (
    <>
      <Skeleton
        variant="rect"
        animation={_animation_type}
        width="100%"
        height={40}
        style={{
          marginBottom: 2,
          marginTop: 2,
          borderRadius: 5,
          backgroundColor: '#d52b1e',
        }}
      />
      {range(1, props.count ?? 5).map((i) => {
        return (
          <Skeleton
            variant="rect"
            animation={_animation_type}
            width="100%"
            height={50}
            style={{ marginBottom: 2, marginTop: 2, borderRadius: 5 }}
          />
        );
      })}

      {/* <Skeleton
        variant="rect"
        animation={_animation_type}
        width="100%"
        height={50}
        style={{ marginBottom: 2, marginTop: 2, borderRadius: 5 }}
      />
      <Skeleton
        variant="rect"
        animation={_animation_type}
        width="100%"
        height={50}
        style={{ marginBottom: 2, marginTop: 2, borderRadius: 5 }}
      />
      <Skeleton
        variant="rect"
        animation={_animation_type}
        width="100%"
        height={50}
        style={{ marginBottom: 2, marginTop: 2, borderRadius: 5 }}
      /> */}
    </>
  );
};

const TextField = (props) => {
  return (
    <Skeleton
      variant="text"
      animation={_animation_type}
      width="100%"
      height={40}
      style={{
        marginBottom: 2,
        marginTop: 2,
        borderRadius: 5,
        backgroundColor: props.bgColor ?? 'rgba(0, 0, 0, 0.11)',
      }}
    />
  );
};

const Consents = (props) => {
  return (
    <Skeleton
      variant="text"
      animation={_animation_type}
      width="100%"
      height={100}
      style={{ marginBottom: 2, marginTop: 2, borderRadius: 5 }}
    />
  );
};

export default { table: Table, textField: TextField, consents: Consents };
