import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Avatar,
  Typography,
  MenuItem,
  Divider,
  ListItemIcon,
  List,
  SwipeableDrawer,
  ListItemText,
  ListItem,
  IconButton,
} from '@material-ui/core';
import MaterialLink from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import {
  GetSessionInformation,
  SetSessionInformationHCP,
} from '../../../Store/Slices/SessionSlice';
import styles from '../../../css/baseStyle';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';

import Menu from '@material-ui/core/Menu';
import { isNullOrUndefined } from 'util';

export default function Nav() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [showAnchor, setShowAnchor] = React.useState(false);
  const [affiliateCode, setAffilaiteCode] = React.useState('NaN');

  const getAffilaiteFromSession = () => {
    console.log('In use states');
    GetSessionInformation().then((data) => {
      let affiliate = '';
      const sessionData = JSON.parse(data.payload);
      console.log(sessionData);
      // setAffiliateName(sessionData._currentUser.affiliate.affiliate_name);
      affiliate = sessionData._data.CountryCode;
      setAffilaiteCode(affiliate);
    });
  };

  React.useEffect(() => {
    getAffilaiteFromSession();
  }, [affiliateCode]);
  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.navRoot}>
      <AppBar position="static" className={styles.ColorbackgroundPrimary}>
        <Toolbar>
          <Avatar
            alt="Lilly"
            src="/public/img/bundle/LillyLogo_White.png"
            variant="square"
            className={styles.avatarSquare}
          />
          {affiliateCode == 'NaN' ? (
            getAffilaiteFromSession()
          ) : (
            <>
              <Typography
                variant="h6"
                className={styles.navRoot}
                onClick={() =>
                  window.location.replace(`/${affiliateCode}/Home`)
                }
              >
                Start Right
              </Typography>
              <div>
                <MaterialLink
                  component="button"
                  variant="button"
                  className={styles.ColorTextSecondary}
                  onClick={async () => {
                    //Code to clear Cookie}
                    await SetSessionInformationHCP(null);
                    window.location.replace('/SelectAffiliate');
                  }}
                >
                  Log Out
                </MaterialLink>
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
