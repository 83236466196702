/* eslint-disable no-multi-spaces */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-return */
import { Grid } from '@material-ui/core';
import axios from 'axios';
import React from 'react';
// import Context from '../../../common/constants/context/base';
import styles from '../../../../css/baseStyle';
import { Order } from '../../../../Store/idb/baseDB';
import FormInputBlock from '../../../common/components/formInputBlock';
import Skeleton from '../../../common/components/Skeleton';
import ApproverTemplate from '../../components/approverTemplate';
import ProductDetails from '../../components/ProductDetails';
// import SelectApprovers from '../../components/selectApprover';
// import FormTemplate from '../../components/formTemplate';
import Consents from '../components/consents';
import HCPSearchDetails from '../components/HCPSearchDetails';
import OrderDetails from '../../components/OrderDetails';

/// /States////
class Brand {
  constructor() {
    this.Name = '';
    this.ID = '';
    this.Products = [];
  }
}
class OrderStatus {
  constructor() {
    this.failureList = {};
    this.successList = {};
    this.underProcessList = {};
    this.riskyOrders = [];
  }
}
/// //Main////
const mainForm = (props) => {
  //#region States
  const [Isonline, SetIsOnline] = React.useState(true);
  const [consentOnly, setConsentOnly] = React.useState(false);
  const [salesRepAddressLine1, setSalesRepAddressLine1] = React.useState('');
  const [salesRepAddressLine2, setSalesRepAddressLine2] = React.useState('');
  const [salesRepAddressLine3, setSalesRepAddressLine3] = React.useState('');
  const [salesRepAddressLine4, setSalesRepAddressLine4] = React.useState('');
  const [comments, setComments] = React.useState();
  const [deliveryInstructions, setDeliveryInstructions] = React.useState();

  const [salesRepAddressAreaCode, setSalesRepAddressAreaCode] = React.useState(
    ''
  );
  const [hcpName, setHCPName] = React.useState('');
  const [hcpID, setHCPID] = React.useState('');
  const [hcpEmail, setHcpEmail] = React.useState('');
  const [hcpOrgName, setHcpOrgName] = React.useState('');
  const [hcpSpeciallity, setHCPSpeciallity] = React.useState('');
  const [hcpaddressDropDown, setHcpaddressDropDown] = React.useState([]);
  const [salesApprover, setSalesApprover] = React.useState('');
  const [attachments, setAttachments] = React.useState([]);
  const [signature, setSignature] = React.useState('');
  const [approvalNow, setApprovalNow] = React.useState(true);
  const [orderDetails, setOrderDetails] = React.useState([]);
  // const [sessionContext, setSessionContext] = React.useState();
  const [HCPAddress, setHCPAddress] = React.useState();
  const [privacyConsent, setPrivacyConsent] = React.useState(false);
  const [marketingConsent, setMarketingConsent] = React.useState(false);
  const [
    marketingCheckboxVisible,
    SetmarketingCheckboxVisible,
  ] = React.useState(true);
  const [dataLoadStatus, setDataLoadStatus] = React.useState(false);
  const [selectedHCPAddress, SetSelectedHCPAddress] = React.useState();
  const [brandState, SetBrandState] = React.useState([]);
  // const [brandState, SetBrandState] = React.useState([]);
  const [brandCheck, SetbrandCheck] = React.useState(false);
  const [productCount, SetProductCount] = React.useState([]);
  const [productDictionary, SetProductDictionary] = React.useState();
  const [productCountCheck, SetproductCountCheck] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState();
  const [sessionCurrentUser, SetSessionCurrentUser] = React.useState();
  const [salesRepConfirmation, setSalesRepConfirmation] = React.useState(false);

  ///validation Consts
  const [hcpNameError, setHcpNameError] = React.useState(false);
  const [line1Error, setLine1Error] = React.useState(false);
  const [line2Error, setLine2Error] = React.useState(false);
  const [line4Error, setLine4Error] = React.useState(false);
  const [areaCodeError, setAreaCodeError] = React.useState(false);
  const [privacyCheckBoxError, setPrivacyCheckBoxError] = React.useState(false);
  const [marketCheckBoxError, setMarketCheckBoxError] = React.useState(false);
  const [salesRepCheckBoxError, setSalesRepCheckBoxError] = React.useState(
    false
  );
  const [fromServerState, SetFromServerState] = React.useState({});
  const [LoadingState, SetLoadingState] = React.useState('Loading...');
  const [formIdUpdate, SetformIdUpdate] = React.useState(0);
  const [affiliateData, SetAffiliateData] = React.useState({});
  ///ENd Valdiation const
  const CheckConsentForEmailAndHCPId = (hcpId, emailAddress, countryCode) => {
    axios
      .get('/api/order/get', {
        headers: {
          URL:
            'api/gccp/consent/' +
            hcpEmail +
            '/' +
            hcpID +
            '/' +
            sessionCurrentUser._currentUser.affiliate.Affiliate_code,
        },
      })
      .then((data) => {
        let _consent = data.data.IsMarketingConsent;
        if (_consent == 1) {
          SetmarketingCheckboxVisible(false);
        } else {
          SetmarketingCheckboxVisible(true);
        }
      })
      .catch((err) => {
        console.log(
          '-------CheckConsentForEmailAndHCPId----------err----',
          err
        );
      });
  };
  const DecryptCodeValue = (id) => {
    let body = {
      key: 'UserInfo',
      value: id,
    };
    console.log(
      '-----------DecryptCodeValue----body----------------------------',
      body
    );
    axios
      .post('/api/clientsupport/decrypt', body)
      .then((data) => {
        console.log(
          '-------------clientsupport-----------------------',
          data.data
        );
        let value = JSON.parse(data.data.payload);
        let _startDate = Date.parse(value.dateStart);
        let _endDate = Date.parse(value.dateEnd);
        let currentDate = new Date();
        if (currentDate < _endDate && currentDate > _startDate) {
          let param = value.id.split('-');
          SetformIdUpdate(param[1]);
          fetchFormData(param[1]);
        } else {
          window.location = '/formExpired.html';
        }
      })
      .catch((err) =>
        console.log(
          '----------DecryptCodeValue---------------err---------',
          err
        )
      );
  };
  React.useEffect(() => {
    // console.log('useEffects');

    DecryptCodeValue(props.match.params.id);
    // CheckOnlineStatus()
    //   .then((data) => SetIsOnline(data.payload))
    //   .catch((err) => SetIsOnline(false));
    // setUserDetails();
    // Context().then((data) => {
    //   console.log(data);
    //   setSessionContext(data);

    //   setTimeout(setDataLoadStatus, 2000, true);
    // });
  }, []);

  const forwardStates = {
    session: {
      get: sessionCurrentUser,
    },
    Comment: {
      get: comments,
      set: setComments,
    },
    hcpModifiedProducts: {
      get: brandState,
      set: SetBrandState,
    },
    selectedHCP: {
      get: selectedHCPAddress,
      set: SetSelectedHCPAddress,
    },
    salesRepAddress: {
      Line1: {
        get: salesRepAddressLine1,
        set: setSalesRepAddressLine1,
      },
      Line2: {
        get: salesRepAddressLine2,
        set: setSalesRepAddressLine2,
      },
      Line3: {
        get: salesRepAddressLine3,
        set: setSalesRepAddressLine3,
      },
      Line4: {
        get: salesRepAddressLine4,
        set: (val) => setSalesRepAddressLine4(val.toUpperCase()),
      },
      AreaCode: {
        get: salesRepAddressAreaCode,
        set: setSalesRepAddressAreaCode,
      },
      Instructions: {
        get: deliveryInstructions,
        set: setDeliveryInstructions,
      },
    },
    Approver: {
      get: salesApprover,
      set: setSalesApprover,
    },
    Attachments: {
      get: attachments,
      set: setAttachments,
    },
    HCP: {
      Name: {
        get: hcpName,
        set: setHCPName,
      },
      ID: {
        get: hcpID,
        set: setHCPID,
      },
      Email: {
        get: hcpEmail,
        set: setHcpEmail,
      },
      Instructions: {
        get: deliveryInstructions,
        set: setDeliveryInstructions,
      },
      Address: {
        get: HCPAddress,
        set: (value) => {
          console.log(value);
          forwardStates.salesRepAddress.Line1.set(value.Line1);
          forwardStates.salesRepAddress.Line2.set(value.Line2);
          forwardStates.salesRepAddress.Line3.set(value.Line3);
          forwardStates.salesRepAddress.Line4.set(value.Line4);
          forwardStates.salesRepAddress.AreaCode.set(value.AreaCode);
          setHCPAddress(value);
        },
      },
      DropDownAddress: {
        get: hcpaddressDropDown,
        set: setHcpaddressDropDown,
      },
      Speciallity: {
        get: hcpSpeciallity,
        set: setHCPSpeciallity,
      },
      orgName: {
        get: hcpOrgName,
        set: setHcpOrgName,
      },
      Consents: {
        Privacy: {
          get: privacyConsent,
          set: setPrivacyConsent,
        },
        Marketing: {
          get: marketingConsent,
          set: setMarketingConsent,
        },
        Visible: {
          get: marketingCheckboxVisible,
          set: SetmarketingCheckboxVisible,
        },
      },
    },
    Order: {
      get: orderDetails,
      set: setOrderDetails,
    },
  };
  const handelSubmit = async (status) => {
    console.log(
      '----------------handelSubmit-------------------------',
      forwardStates
    );
    let body = { 
      status: status,
      orderId: formIdUpdate,
      countryCode: affiliateData.Affiliate_code,
      countryName: affiliateData.affiliate_name,
    };
    axios
      .post('/api/order/post', body, {
        headers: {
          URL: 'api/sampleorderoffline/UpdateStatusHCPApproval',
        },
      })
      .then((data) =>
        console.log(
          '------UpdateStatusHCPApproval----------------------',
          data.data
        )
      )
      .catch((err) =>
        console.log(
          '-------------------UpdateStatusHCPApproval-----------------err--------',
          err
        )
      );

    // need to remove the successful orders from the DB
  };
  const LoadAffiliateDate = (id) => {
    axios
      .get('/api/admin/get', {
        headers: {
          URL: 'adminService/User/GetAffiliateByAffiliateId/' + id,
        },
      })
      .then((data) => {
        console.log(
          '------SetAffiliateData-------------------------------',
          data.data
        );
        SetAffiliateData(data.data);
      })
      .catch((err) =>
        console.log('--------LoadAffiliateDate--------------err-------', err)
      );
  };
  let from_server = fromServerState;
  //#endregion
  const fetchFormData = (orderID) => {
    axios
      .get('/api/order/get', {
        headers: {
          URL: 'api/sampleorderoffline/GetOrderById/' + orderID,
        },
      })
      .then((data) => {
        let form = data.data;
        console.log(
          '-----------LoadFormData--------form-----------------------',
          form
        );
        LoadAffiliateDate(form.affiliate_id);
        if (form.request_status === 'Pending Approval') {
          forwardStates.HCP.Name.set(form.hcp_name);
          forwardStates.HCP.Email.set(form.hcp_email);
          forwardStates.HCP.ID.set(form.hcp_veeva_id);
          forwardStates.HCP.Speciallity.set(form.speciality);
          forwardStates.HCP.Instructions.set(form.delivery_instructions);
          forwardStates.HCP.orgName.set();
          forwardStates.salesRepAddress.Line1.set(
            form.hcp_address_object.line1
          );
          forwardStates.salesRepAddress.Line2.set(
            form.hcp_address_object.line2
          );
          forwardStates.salesRepAddress.Line3.set(
            form.hcp_address_object.line3
          );
          forwardStates.salesRepAddress.Line4.set(
            form.hcp_address_object.line4
          );
          forwardStates.salesRepAddress.AreaCode.set(
            form.hcp_address_object.areaCode
          );
          forwardStates.HCP.Consents.Marketing.set(form.consent_marketing);
          forwardStates.HCP.Consents.Privacy.set(form.consent_email);
          setOrderDetails(form.products);
          let _from_server = {
            SalesRep: {
              Name: form.lilly_employee_name,
              ID: form.lilly_id,
              FormID: form.order_id,
              Email: form.lilly_email,
            },
            Products: {
              TotalCount: productCount,
              Brands: brandState,
            },
          };
          SetFromServerState(_from_server);
          SetbrandCheck(true);
        } else {
          SetLoadingState('Form Already Approved');
          window.location = '/formExpired.html';
        }
        //   orderDetails.concat([
        //     {
        //       Id: 1,
        //       Name: 'Humalog',
        //       Packsize: '10 mg',
        //       Quantity: 5,
        //     },
        //     {
        //       Id: 1,
        //       Name: 'Humaline',
        //       Packsize: '5 mg',
        //       Quantity: 6,
        //     },
        //   ])
        // );
      })
      .catch((err) =>
        console.log('--------fetchFormData-----err-----------------', err)
      );
  };
  // const from_server = {
  //   SalesRep: {
  //     Name: 'Jivesh Gautam',
  //     ID: 'C261007',
  //     FormID: 'REQ001T3',
  //     Email: 'gautam_jivesh@network.lilly.com',
  //   },
  //   Products: {
  //     TotalCount: productCount,
  //     Brands: brandState,
  //   },
  // };
  return (
    <>
      {!brandCheck ? (
        LoadingState
      ) : (
        <div className={`${styles.paddingWihtoutHeaders}`}>
          <div className={`${styles.containerPaper}`}>
            <h3 className={styles.headerBottomPadding}>Sample Order Form</h3>
          </div>

          <ApproverTemplate
            baseDetails={from_server.SalesRep}
            attachments={forwardStates.Attachments}
            states={forwardStates}
            validate={() => validate()}
            approvers={from_server.Approvers}
            toApprove={() => handelSubmit('Submitted')}
            toReject={() => handelSubmit('Rejected')}
            auditTrail={[]}
          >
            <HCPSearchDetails
              hcpDetails={forwardStates.HCP}
              salesRepDetails={sessionCurrentUser}
              address={forwardStates.selectedHCP}
              modifyProductStates={forwardStates.hcpModifiedProducts}
              session={forwardStates.session}
              Disabled={true}
              hcpNameError={hcpNameError}
            />
            <Grid container>
              {/* Address */}
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  label="Address Line 1"
                  value={forwardStates.salesRepAddress.Line1.get}
                  setValue={forwardStates.salesRepAddress.Line1.set}
                  isRequired={true}
                  isDisabled={true}
                  error={line1Error}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  label="Address Line 2"
                  value={forwardStates.salesRepAddress.Line2.get}
                  setValue={forwardStates.salesRepAddress.Line2.set}
                  isDisabled={true}
                  error={line2Error}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  label="City"
                  value={forwardStates.salesRepAddress.Line3.get}
                  setValue={forwardStates.salesRepAddress.Line3.set}
                  isRequired={true}
                  isDisabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  label="State"
                  value={forwardStates.salesRepAddress.Line4.get}
                  setValue={forwardStates.salesRepAddress.Line4.set}
                  isDisabled={true}
                  isRequired={true}
                  error={line4Error}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  label="Post Code"
                  value={forwardStates.salesRepAddress.AreaCode.get}
                  setValue={forwardStates.salesRepAddress.AreaCode.set}
                  isRequired={true}
                  error={areaCodeError}
                  isDisabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="email"
                  label="Email"
                  value={forwardStates.HCP.Email.get}
                  setValue={forwardStates.HCP.Email.set}
                  isDisabled={true}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormInputBlock
                  label="Deliver Instructions"
                  value={forwardStates.salesRepAddress.Instructions.get}
                  setValue={forwardStates.salesRepAddress.Instructions.set}
                  multiline={true}
                  isDisabled={true}
                />
              </Grid>
            </Grid>
            {/* <HCPDetailsSales hcpDetails={from_server.HCP} /> */}
            {/* <SalesRep
          salesRepDetails={from_server.SalesRep}
          states={forwardStates}
        /> */}

            <OrderDetails
              TotalCount={orderDetails.length}
              Orders={orderDetails}
              stateCheck={props.stateCheck}
              error={props.error}
            />

            <Consents
              states={forwardStates.HCP.Consents}
              privacyError={privacyCheckBoxError}
              marketingError={marketCheckBoxError}
              isDisabled={true}
            />
          </ApproverTemplate>
        </div>
      )}
    </>
  );
};

export default mainForm;
