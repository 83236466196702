import React from 'react';
/// //////////React Boostrap/////////
// import { Button, Form } from 'react-bootstrap';

// Material UI
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AddCircleOutlineIcon } from '@material-ui/icons';
import {
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  Paper,
  FormHelperText,
  Grid,
  Checkbox,
  Hidden,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import styles from '../../../../css/baseStyle';
import Skeleton from '../../../common/components/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '1% 8% 4% 8%',

    '& > *': {
      //   margin: theme.spacing(1),
      //   width: theme.spacing(16),
      //   height: theme.spacing(16),

      width: '100%',
      height: 'auto',
    },
  },
  depotTitle: {
    margin: '0% 0% 2% 2%',
    color: '#333232',
  },
  depotPaper: {
    // width: '100%',
    // height: 'auto',
    //margin: '4% 8% 4% 8%',

    padding: '3%',
    marginBottom: '4%',
  },
  depotSubTitle: {
    margin: '0% 0% 2% 0%',
    color: '#333232',
    float: 'left',
  },
  table: {
    minWidth: 700,
  },
  depotButtonTypo: {
    float: 'right',
  },
  depotButton: {
    color: '#fff',
    background: '#D52B1E',
    textAlign: 'center',
    '&:hover': {
      background: '#D52B1E',
    },
  },
  headerBottomPadding: {
    marginBottom: '20px',
  },
  labelCheckBoxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  labelText: {
    textAlign: 'left !important',
    font: 'normal normal normal 16px/21px Roboto',
    color: '#333232',
    opacity: '1',
  },
  textFieldMaxWidth: {
    width: '100% !important',
  },
  paperMaxWidth: {
    width: '100% !important',
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
}));

const consents = (props) => {
  const classes = useStyles();
  const handleClick = (type) => {
    switch (type) {
      case 'submit':
        setTextSnackBar('Form submitted successfully!');
        setTypeSnackBar('success');
        break;
      case 'error':
        setTextSnackBar('Form deleted!');
        setTypeSnackBar('warning');
        break;
      case 'save':
        setTextSnackBar('Form Saved in drafts!');
        setTypeSnackBar('info');
        break;
    }
    setOpenSnackBar(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  return (
    <div>
      {/* <Paper className={classes.paperMaxWidth}> */}
      <Grid container>
        {props.stateCheck ? (
          <Skeleton.consents />
        ) : (
          <>
            <FormControl error={props.error} component="fieldset">
              <FormControlLabel
                value="end"
                classes={{
                  label: styles.confirmationLabelFontSize,
                  root: styles.checkboxPadding,
                }}
                control={
                  <Checkbox
                    id="checkBoxConfirmation"
                    color="primary"
                    value={props.states.get}
                    onChange={() => props.states.set((prevState) => !prevState)}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                }
                label="I confirm I am a registered Healthcare Practitioner
          authorised to prescribe medicines I have requested, and will
          be the recipient of this order"
                labelPlacement="end"
              />
              {props.error ? (
                <FormHelperText className={styles.checkboxhelperPadding}>
                  *Confirmation is required!!
                </FormHelperText>
              ) : (
                ''
              )}
            </FormControl>
          </>
        )}
      </Grid>
      {/* </Paper> */}

      {/* #endregion */}
    </div>
  );
};

export default consents;
